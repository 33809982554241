import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Button, DataGrid, Link, Tab, Tabs, TextField } from '@ghs/components';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useUnit } from 'effector-react';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useContext, useState } from 'react';
import { Link as RouterLink, createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import TabPanel from '../components/TabPanel';
import ToggleButton, { ToggleButtonGroup } from '../components/ToggleButton';
import { $$tutorialVideos } from '../components/TutorialVideosModal/model';
import TutorialVideoButton from '../components/TutorialVideosModal/TutorialVideoButton';
import useAuth0WithErrorHandling from '../hooks/useAuth0WithErrorHandling';
import { $$contactService } from '../services/ContactService/model';
import { fetchLookupByNameResults, fetchLookupByPieceNumResults } from '../services/DataService';
import { $$personaService } from '../services/PersonaService/model';
import { UserContext } from '../UserContext';
import { formatZip, trimObject } from '../util/AddressUtils';
import { generateErrorAlertWithContact, generateTimeoutErrorAlert, isTimeoutError } from '../util/ErrorUtils';
dayjs.extend(customParseFormat);

const LOOKUP_BY_PIECE_NUM = 0;
const LOOKUP_BY_NAME = 1;
const defaultDateRange = [dayjs().subtract(1, 'month'), dayjs()];
const defaultSearchResults = { isLoaded: false, rows: [] };

const Lookup = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0WithErrorHandling();
  const { user } = useAuth0();
  const contactInfo = useUnit($$contactService.$contactInfo);

  const defaultFormData = {
    pieceNumberType: 'imb',
    imb: '',
    uniqueRecordId: '',
    dateRange: defaultDateRange,
    name: '',
    businessName: '',
    city: '',
    zip: '',
    userField1: '',
    userField2: '',
    userField3: '',
    errors: {}
  };
  const formDataFromSearchParams = {
    pieceNumberType: searchParams.get('uniqueRecordId') ? 'uniqueRecordId' : 'imb',
    imb: searchParams.get('imb') || '',
    uniqueRecordId: searchParams.get('uniqueRecordId') || '',
    dateRange: [
      searchParams.get('startDate') ? dayjs(searchParams.get('startDate'), 'MM-DD-YYYY') : dayjs().subtract(1, 'month'),
      searchParams.get('endDate') ? dayjs(searchParams.get('endDate'), 'MM-DD-YYYY') : dayjs()
    ],
    name: searchParams.get('name') || '',
    businessName: searchParams.get('businessName') || '',
    city: searchParams.get('city') || '',
    zip: searchParams.get('zip') || '',
    userField1: searchParams.get('userField1') || '',
    userField2: searchParams.get('userField2') || '',
    userField3: searchParams.get('userField3') || '',
    errors: {}
  };
  const [formData, setFormData] = useState(formDataFromSearchParams);
  const [selectedTab, setSelectedTab] = useState(parseInt(searchParams.get('lookupType')) || LOOKUP_BY_PIECE_NUM);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [searchResults, setSearchResults] = useState(defaultSearchResults);
  const [alertData, setAlertData] = useState(null);

  const activePersonaGhsSystem = useUnit($$personaService.$activePersona.map(persona => persona?.ghsSystem || null));

  const handleTabSelect = (_e, newTabSelection) => {
    setSelectedTab(newTabSelection);
  };

  const handleFormChange = newData => {
    // Enforce piece number type always having a selection
    if (!newData.pieceNumberType) {
      newData.pieceNumberType = formData.pieceNumberType;
    }
    setFormData({
      ...formData,
      ...newData
    });
  };

  const isFormValid = () => {
    const errors = {};
    if (selectedTab === LOOKUP_BY_PIECE_NUM) {
      if (formData.pieceNumberType === 'imb' && !formData.imb) {
        errors.imb = 'IMB is required';
      }
      if (formData.pieceNumberType === 'uniqueRecordId' && !formData.uniqueRecordId) {
        errors.uniqueRecordId = 'Unique Record ID is required';
      }
    } else if (selectedTab === LOOKUP_BY_NAME) {
      if (!(formData.name || formData.businessName)) {
        errors.name = 'Full Name or Business Name is required';
        errors.businessName = 'Full Name or Business Name is required';
      }
    }

    let dateErrors = {};
    if (!formData.dateRange[0].isValid()) {
      dateErrors.start = 'Valid Start Date is required';
    }
    if (!formData.dateRange[1].isValid()) {
      dateErrors.end = 'Valid End Date is required';
    }

    if (Object.keys(dateErrors).length > 0) {
      errors.dateRange = dateErrors;
    }

    setFormData({ ...formData, ...{ errors: errors } });
    return Object.keys(errors).length === 0;
  };

  const handleSubmitSearch = async event => {
    event.preventDefault();
    if (!searchInProgress && isFormValid()) {
      // Instead of triggering a data load here, we will set the search params. This will trigger a render and data will
      // be loaded by `handleInitialLoad` inside the `useEffect` hook.
      if (selectedTab === LOOKUP_BY_PIECE_NUM) {
        navigate(
          {
            pathname: '/lookup',
            search: createSearchParams({
              imb: formData.imb,
              uniqueRecordId: formData.uniqueRecordId,
              startDate: formData.dateRange[0].format('MM-DD-YYYY'),
              endDate: formData.dateRange[1].format('MM-DD-YYYY'),
              t: dayjs().valueOf()
            }).toString()
          },
          { relative: 'path' }
        );
      } else {
        navigate(
          {
            pathname: '/lookup',
            search: createSearchParams({
              lookupType: LOOKUP_BY_NAME,
              name: formData.name,
              businessName: formData.businessName,
              city: formData.city,
              zip: formData.zip,
              userField1: formData.userField1,
              userField2: formData.userField2,
              userField3: formData.userField3,
              startDate: formData.dateRange[0].format('MM-DD-YYYY'),
              endDate: formData.dateRange[1].format('MM-DD-YYYY'),
              t: dayjs().valueOf()
            }).toString()
          },
          { relative: 'path' }
        );
      }
    }
  };

  const loadData = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      let rows;
      if (selectedTab === LOOKUP_BY_PIECE_NUM) {
        rows = await fetchLookupByPieceNumResults(
          {
            imb: formData.imb,
            uniqueRecordId: formData.uniqueRecordId,
            origin: false,
            startDate: formData.dateRange[0],
            endDate: formData.dateRange[1]
          },
          accessToken
        );
      } else {
        rows = await fetchLookupByNameResults(
          {
            name: formData.name,
            businessName: formData.businessName,
            city: formData.city,
            zip: formData.zip,
            userField1: formData.userField1,
            userField2: formData.userField2,
            userField3: formData.userField3,
            origin: false,
            startDate: formData.dateRange[0],
            endDate: formData.dateRange[1]
          },
          accessToken
        );
      }
      setSearchResults({ isLoaded: true, rows: rows.map(trimObject) });
    } catch (err) {
      console.error('Error fetching data: ', err);
      if (isTimeoutError(err)) {
        setAlertData(generateTimeoutErrorAlert(user?.email, window.location.href));
      } else {
        setAlertData(
          generateErrorAlertWithContact({
            contactInfo: contactInfo,
            userEmail: user?.email,
            requestId: err?.response?.headers?.get('Request-Id'),
            errorMessage: 'There was an error loading your search results. Please retry your search'
          })
        );
      }
    }
  };

  React.useEffect(() => {
    const handleInitialLoad = async () => {
      // Only load data if there are searchParams and the form is valid
      if ((searchParams.size || [...new Set(searchParams.keys())].length) && isFormValid()) {
        setSearchResults(defaultSearchResults);
        setSearchInProgress(true);

        await loadData();
        setSearchInProgress(false);
      }
    };

    handleInitialLoad();
  }, [searchParams]);

  return (
    <Container id="lookup-form" data-testid="lookup-form" component="form">
      <TutorialVideoButton url={activePersonaGhsSystem === 'MAP' ? $$tutorialVideos.links['SPL:MT_PERSONA'] : $$tutorialVideos.links['SPL:ST_PERSONA']} />

      <Collapse in={!!alertData && (alertData.title || alertData.message)}>
        <Alert
          severity={alertData?.severity}
          action={
            <IconButton onClick={() => setAlertData({ ...alertData, ...{ title: '', message: '' } })}>
              <CloseIcon />
            </IconButton>
          }
        >
          <Typography variant="h6">{alertData?.title}</Typography>
          <Typography>{alertData?.message}</Typography>
        </Alert>
      </Collapse>
      <Container>
        <Tabs value={selectedTab} variant="fullWidth" scrollButtons="auto" onChange={handleTabSelect}>
          <Tab label="Lookup by Piece Number"></Tab>
          <Tab label="Lookup by Name"></Tab>
        </Tabs>
      </Container>
      <Container>
        <TabPanel value={selectedTab} index={0}>
          <LookupByPieceNumber model={formData} onChange={handleFormChange}></LookupByPieceNumber>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <LookupByName model={formData} onChange={handleFormChange}></LookupByName>
        </TabPanel>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            value={formData.dateRange}
            onChange={newValue => handleFormChange({ dateRange: newValue })}
            localeText={{ start: 'Start Date', end: 'End Date' }}
            slots={{
              shortcuts: CustomRangeShortcuts
            }}
            slotProps={{
              shortcuts: {
                items: shortcutsItems
              },
              toolbar: {
                hidden: true
              },
              actionBar: {
                hidden: true
              },
              fieldSeparator: {
                sx: { color: 'text.primary' }
              },
              textField: ({ position }) => {
                if (position === 'start') {
                  return {
                    error: !!formData.errors?.dateRange?.start,
                    helperText: formData.errors?.dateRange?.start
                  };
                }
                if (position === 'end') {
                  return {
                    error: !!formData.errors?.dateRange?.end,
                    helperText: formData.errors?.dateRange?.end
                  };
                }
              }
            }}
            calendars={2}
            sx={{ ml: 6, mr: 6, maxWidth: '400px' }}
          />
        </LocalizationProvider>
        <Stack direction="row" spacing={2} sx={{ mt: 2, ml: 6 }}>
          <Button
            color="secondary"
            onClick={() => {
              setFormData(defaultFormData);
              setSearchParams({});
            }}
            size="medium"
            variant="contained"
            disabled={searchInProgress}
          >
            Clear
          </Button>
          <Button color="primary" onClick={handleSubmitSearch} size="medium" variant="contained" disabled={searchInProgress} type="submit">
            Search
          </Button>
        </Stack>
      </Container>
      <Container sx={{ mt: 2 }}>
        {React.useMemo(
          () => (
            <SearchResults data={searchResults} loading={searchInProgress} />
          ),
          [JSON.stringify(searchResults), searchInProgress]
        )}
      </Container>
    </Container>
  );
};

Lookup.propTypes = {
  /** User's email address. */
  userEmail: PropTypes.string
};

/**
 * Component for displaying the form for Lookup By Piece Number.
 *
 * @param {object} props props for this component
 * @param {object} props.model the model backing the form
 * @param {Function} props.onChange handler for when the form changes
 * @returns {Element} LookupByPieceNumber component
 * @class
 */
function LookupByPieceNumber({ model, onChange }) {
  const handlePieceNumberType = (_event, newPieceNumberType) => {
    onChange({ pieceNumberType: newPieceNumberType, imb: '', uniqueRecordId: '' });
  };
  const handleFormChange = event => onChange({ [event.target.name]: event.target.value });

  return (
    <Container>
      <Stack>
        <FormControl>
          <ToggleButtonGroup color="primary" exclusive value={model.pieceNumberType} onChange={handlePieceNumberType} sx={{ mb: 1 }}>
            <ToggleButton value="imb" sx={{ textTransform: 'none' }}>
              IMb
            </ToggleButton>
            <ToggleButton value="uniqueRecordId">Unique Record ID</ToggleButton>
          </ToggleButtonGroup>
          <TextField
            name="imb"
            label="IMb (1st 20 digits)"
            value={model.imb}
            onChange={handleFormChange}
            error={!!model.errors.imb}
            helperText={model.errors.imb}
            inputProps={{ maxLength: 20 }}
            sx={{ maxWidth: '400px', ...(model.pieceNumberType !== 'imb' && { display: 'none' }) }}
          />
          <TextField
            name="uniqueRecordId"
            label="Unique Record ID"
            value={model.uniqueRecordId}
            onChange={handleFormChange}
            error={!!model.errors.uniqueRecordId}
            helperText={model.errors.uniqueRecordId}
            sx={{ maxWidth: '400px', ...(model.pieceNumberType !== 'uniqueRecordId' && { display: 'none' }) }}
          />
        </FormControl>
      </Stack>
    </Container>
  );
}

LookupByPieceNumber.propTypes = {
  model: PropTypes.shape({
    pieceNumberType: PropTypes.string,
    imb: PropTypes.string,
    errors: PropTypes.shape({
      imb: PropTypes.string,
      uniqueRecordId: PropTypes.string
    }),
    uniqueRecordId: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

const shortcutsItems = [
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    }
  },
  {
    label: 'Last 3 Months',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(3, 'month'), today];
    }
  },
  {
    label: 'This Month',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    }
  },
  { label: 'Reset', getValue: () => defaultDateRange }
];

/**
 * Component for displaying the form for Lookup By Name.
 *
 * @param {object} props props for this component
 * @param {object} props.model the model backing the form
 * @param {Function} props.onChange handler for when the form changes
 * @returns {Element} LookupByName component
 * @class
 */
function LookupByName({ model, onChange }) {
  const userDetails = useContext(UserContext);
  const handleFormChange = event => onChange({ [event.target.name]: event.target.value });
  return (
    <Container>
      <Stack>
        <TextField label="Full Name" name="name" value={model.name} onChange={handleFormChange} error={!!model.errors.name} helperText={model.errors.name} sx={{ mb: 1 }}></TextField>
        <TextField
          label="Business Name"
          name="businessName"
          value={model.businessName}
          onChange={handleFormChange}
          error={!!model.errors.businessName}
          helperText={model.errors.businessName}
          sx={{ mb: 1 }}
        ></TextField>
        <TextField label="City" name="city" value={model.city} onChange={handleFormChange} error={!!model.errors.city} helperText={model.errors.city} sx={{ mb: 1 }}></TextField>
        <TextField label="5-Digit ZIP" name="zip" value={model.zip} onChange={handleFormChange} error={!!model.errors.zip} helperText={model.errors.zip} sx={{ mb: 1 }}></TextField>
        {userDetails.isSt && (
          <>
            <TextField
              label="User field 1"
              name="userField1"
              value={model.userField1}
              onChange={handleFormChange}
              error={!!model.errors.userField1}
              helperText={model.errors.userField1}
              sx={{ mb: 1 }}
            />
            <TextField
              label="User field 2"
              name="userField2"
              value={model.userField2}
              onChange={handleFormChange}
              error={!!model.errors.userField2}
              helperText={model.errors.userField2}
              sx={{ mb: 1 }}
            />
            <TextField
              label="User field 3"
              name="userField3"
              value={model.userField3}
              onChange={handleFormChange}
              error={!!model.errors.userField3}
              helperText={model.errors.userField3}
              sx={{ mb: 1 }}
            />
          </>
        )}
      </Stack>
    </Container>
  );
}

LookupByName.propTypes = {
  model: PropTypes.shape({
    name: PropTypes.string,
    businessName: PropTypes.string,
    city: PropTypes.string,
    errors: PropTypes.shape({
      name: PropTypes.object,
      city: PropTypes.object,
      businessName: PropTypes.object,
      zip: PropTypes.object,
      userField1: PropTypes.object,
      userField2: PropTypes.object,
      userField3: PropTypes.object
    }),
    zip: PropTypes.string,
    userField1: PropTypes.string,
    userField2: PropTypes.string,
    userField3: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

/**
 * Component for displaying the form for Custom Range Shortcuts for date selection.
 *
 * @param {object} props props for this component
 * @param {Array} props.items array of shortcut options
 * @param {Function} props.onChange handler for when a shortcut is selected
 * @param {Function} props.isValid handler for whether a section is valid
 * @returns {Element} CustomRangeShortcuts component
 * @class
 */
function CustomRangeShortcuts(props) {
  const { items, onChange, isValid } = props;

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map(item => {
    const newValue = item.getValue({ isValid });

    return {
      label: item.label,
      onClick: () => {
        onChange(newValue);
      },
      disabled: !isValid(newValue)
    };
  });

  return (
    <Box
      sx={{
        gridRow: 1,
        gridColumn: 2
      }}
    >
      <List
        dense
        sx={theme => ({
          display: 'flex',
          px: theme.spacing(4),
          '& .MuiListItem-root': {
            pt: 0,
            pl: 0,
            pr: theme.spacing(1)
          }
        })}
      >
        {resolvedItems.map(item => {
          return (
            <ListItem key={item.label}>
              <Chip {...item} />
            </ListItem>
          );
        })}
      </List>
      <Divider />
    </Box>
  );
}

CustomRangeShortcuts.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  isValid: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

/**
 * Component for displaying the search results.
 *
 * @param {object} props props for this component
 * @param {object} props.data object containing rows and loading state
 * @param {boolean} props.loading whether the data is being loaded
 * @returns {React.ReactNode} SearchResults component
 */
function SearchResults({ data, loading }) {
  const userDetails = useContext(UserContext);

  const getDetailPanelContent = ({ row }) => {
    const userFields = getUserFields(row);
    if (userFields.length > 0) {
      return (
        <List sx={{ p: 1, ml: 1 }}>
          {userFields.map((userField, index) => {
            return (
              <ListItem key={`user-field-list-item-${index}`} sx={{ my: 0, p: 0 }}>
                <ListItemText primary={userField} />
              </ListItem>
            );
          })}
        </List>
      );
    } else {
      // By returning null, the expand button will be disabled
      return null;
    }
  };

  const buildName = row => {
    let nameParts = [];
    if (row.name) {
      nameParts.push(row.name);
    }
    if (row.businessName) {
      nameParts.push(row.businessName);
    }
    return nameParts.join('\n');
  };

  const buildAddress = row => {
    let addressParts = [];
    if (row.address) {
      addressParts.push(row.address);
    }
    // As per Product request, City & State must always be in the same line. If ZIP fits in the same then fine, if not it'll go to its own line
    if (row.city || row.state || row.zip) {
      let cityStateZip = [];
      if (row.city) {
        cityStateZip.push(row.city);
      }
      if (row.state) {
        cityStateZip.push(row.state);
      }
      if (row.zip) {
        cityStateZip.push(formatZip(row.zip));
      }
      addressParts.push(cityStateZip.join(', '));
    }
    return addressParts.join('\n');
  };

  const getUserFields = row => {
    let userFields = [];
    if (row.userField1) {
      userFields.push(row.userField1);
    }
    if (row.userField2) {
      userFields.push(row.userField2);
    }
    if (row.userField3) {
      userFields.push(row.userField3);
    }
    return userFields;
  };

  const columns = [
    {
      renderCell: params => (
        <Link component={RouterLink} to={`/splResults?pkgId=${params.row.pkgId}&trackCode=${params.row.trackCode}`}>{`${params.row.clientSeqNum ?? 'BLANK'}${params.row.hasAcs ? '*' : ''}`}</Link>
      ),
      valueFormatter: params => params?.value ?? 'BLANK',
      field: 'clientSeqNum',
      headerName: 'Unique Record ID',
      flex: 1,
      hideable: false
    },
    {
      field: 'mailDate',
      headerName: 'Mail Date',
      flex: 0.5,
      minWidth: 120,
      valueGetter: params => dayjs(params).toDate(),
      valueFormatter: date => dayjs(date).format('MM/DD/YYYY')
    },
    { field: 'product', headerName: 'Campaign', flex: 1 },
    {
      field: 'name',
      headerName: 'Name / Business Name',
      renderCell: params => (
        <Typography variant="h8" sx={{ whiteSpace: 'pre-line' }}>
          {buildName(params.row)}
        </Typography>
      ),
      flex: 1
    },
    {
      field: 'address',
      headerName: 'Address',
      renderCell: params => (
        <Typography variant="h8" sx={{ whiteSpace: 'pre-line' }}>
          {buildAddress(params.row)}
        </Typography>
      ),
      flex: 1
    }
  ];

  return data.isLoaded ? (
    <Container id="search-results-container" data-testid="search-results-container">
      <Typography variant="body2" color="text.primary" sx={{ fontStyle: 'italic' }}>
        * indicates ACS data in the record
      </Typography>
      <DataGrid
        columns={columns}
        rows={data.rows}
        rowSelection={false}
        autoHeight
        pagination
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          }
        }}
        pageSizeOptions={[5, 10, 25]}
        // Wrap long cells
        getRowHeight={() => 'auto'}
        // Detail panel configuration
        getDetailPanelContent={userDetails.isSt ? getDetailPanelContent : null}
        getDetailPanelHeight={userDetails.isSt ? () => 'auto' : null}
        getDetailPanelVariant={userDetails.isSt ? 'standard' : null}
        // Restore padding due to using getRowHeight & disable cell selection outline
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          '& .MuiDataGrid-cell:focus': { outline: 'none' },
          '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
          '.MuiDataGrid-cell': { overflowWrap: 'anywhere' }
        }}
        localeText={{
          noRowsLabel: 'No results'
        }}
      />
    </Container>
  ) : (
    <Box sx={{ display: 'flex' }} justifyContent="center">
      <CircularProgress sx={{ ...(!loading && { display: 'none' }) }} />
    </Box>
  );
}

SearchResults.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        clientSeqNum: PropTypes.string,
        mailDate: PropTypes.string,
        product: PropTypes.string,
        name: PropTypes.string,
        businessName: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        userField1: PropTypes.string,
        userField2: PropTypes.string,
        userField3: PropTypes.string,
        hasAcs: PropTypes.bool
      })
    ).isRequired,
    isLoaded: PropTypes.bool.isRequired
  }).isRequired,
  loading: PropTypes.bool.isRequired
};

export default Lookup;
