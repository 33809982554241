// @ts-ignore
import { buttonColors } from '@ghs/components';
import { Button, Tooltip, Typography } from '@mui/material';
import { createElement } from 'react';
import { $$confirmationDialog } from '../../../components/ConfirmationDialog/model';
import ConfirmationWithMemoryContent from '../../../components/ConfirmationWithMemoryContent/ConfirmationWithMemoryContent';
// @ts-ignore
import { pick } from 'lodash';
import IconButton from '../../../components/IconButton/IconButton';
import { $$resellerUserDialog } from '../../../components/ResellerUserDialog/model';
import UserCell from './UserCell';

/**
 *
 * @param {{
 *  userId: Reseller.User['id'];
 *  deleteConfirmationSkipChecked: import('effector').EventCallable<boolean>;
 *  deleteConfirmed: import('effector').EventCallable<Reseller.User['id']>;
 * }} params - params
 * @returns {typeof $$confirmationDialog['$state']['__']} - confirmation dialog arg
 */
export const createCustomersUserDeleteConfirmationDialogArg = params => {
  const { userId, deleteConfirmationSkipChecked, deleteConfirmed } = params;
  return {
    title: `Delete Customer User`,
    content: createElement(ConfirmationWithMemoryContent, {
      title: 'Are you sure you want to delete this user?',
      comment: 'Access will be deleted for all Lines Of Business and Campaigns within this Customer.',
      onValueChange: deleteConfirmationSkipChecked
    }),
    buttons: [
      {
        children: 'Cancel',
        variant: 'outlined',
        onClick: () => {
          $$confirmationDialog.close();
        }
      },
      {
        children: 'Yes, Delete User',
        variant: 'contained',
        color: buttonColors.ERROR,
        onClick: () => {
          deleteConfirmed(userId);
          $$confirmationDialog.close();
        }
      }
    ]
  };
};

/**
 *
 * @param {{
 *  lobId: Reseller.LineOfBusiness['id'];
 *  deleteConfirmationSkipChecked: import('effector').EventCallable<boolean>;
 *  deleteConfirmed: import('effector').EventCallable<Reseller.LineOfBusiness['id']>;
 * }} params - params
 * @returns {typeof $$confirmationDialog['$state']['__']} - confirmation dialog arg
 */
export const createLobsUserDeleteConfirmationDialogArg = params => {
  const { lobId, deleteConfirmationSkipChecked, deleteConfirmed } = params;
  return {
    title: `Delete Line of Business User`,
    content: createElement(ConfirmationWithMemoryContent, {
      title: 'Are you sure you want to delete this user?',
      comment: 'Access will be deleted for all Campaigns within this Line of Business.',
      onValueChange: deleteConfirmationSkipChecked
    }),
    buttons: [
      {
        children: 'Cancel',
        variant: 'outlined',
        onClick: () => {
          $$confirmationDialog.close();
        }
      },
      {
        children: 'Yes, Delete User',
        variant: 'contained',
        color: buttonColors.ERROR,
        onClick: () => {
          deleteConfirmed(lobId);
          $$confirmationDialog.close();
        }
      }
    ]
  };
};

/**
 *
 * @param {{
 *  deleteConfirmationSkipChecked: import('effector').EventCallable<boolean>;
 * }} params - params
 * @returns {typeof $$confirmationDialog['$state']['__']} - confirmation dialog arg
 */
export const createCustomersUserCreateSuccessDialogArg = params => {
  const { deleteConfirmationSkipChecked } = params;
  return {
    title: `Customer User Added Successfully!`,
    content: createElement(ConfirmationWithMemoryContent, {
      title: '',
      comment:
        'This user will be assigned the Default roles of LOB Admin, Portal Reporting & Customer Logos when they log in. If a change is needed, updates can be made in the Roles/Permissions page after the user has logged in.',
      onValueChange: deleteConfirmationSkipChecked
    }),
    buttons: [
      {
        children: 'Cancel',
        variant: 'outlined',
        onClick: () => {
          $$confirmationDialog.close();
        }
      },
      {
        children: 'Confirm',
        variant: 'contained',
        onClick: () => {
          $$confirmationDialog.close();
        }
      }
    ]
  };
};

/**
 *
 * @param {{
 *  deleteConfirmationSkipChecked: import('effector').EventCallable<boolean>;
 * }} params - params
 * @returns {typeof $$confirmationDialog['$state']['__']} - confirmation dialog arg
 */
export const createLobsUserCreateSuccessDialogArg = params => {
  const { deleteConfirmationSkipChecked } = params;
  return {
    title: `Line of Business User Added Successfully!`,
    content: createElement(ConfirmationWithMemoryContent, {
      title: '',
      comment:
        'This user will be assigned the default role of Portal Reporting when they log in. If a change is needed, updates can be made in the Roles/Permissions page after the user has logged in.',
      onValueChange: deleteConfirmationSkipChecked
    }),
    buttons: [
      {
        children: 'Cancel',
        variant: 'outlined',
        onClick: () => {
          $$confirmationDialog.close();
        }
      },
      {
        children: 'Confirm',
        variant: 'contained',
        onClick: () => {
          $$confirmationDialog.close();
        }
      }
    ]
  };
};

/**
 * createUsersTableColumns
 *
 * @param {object} props - props
 * @returns {import('@mui/x-data-grid-pro').GridColDef<Reseller.User | Reseller.LineOfBusiness>[]} - columns
 */
export const createUsersTableColumns = props => {
  const { dataGridProps, nameColumnHeaderTitle, addUserButtonTooltip, onDownloadClick, onAddUserClick, onEditSubmitted, onDeleteClicked } = props;
  return [
    {
      field: 'email',
      headerName: nameColumnHeaderTitle,
      minWidth: 160,
      flex: 8,
      renderHeader: () => (
        <Typography variant="body2" fontWeight="500" sx={{ gap: 2, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          <span>{nameColumnHeaderTitle}</span>
          <IconButton type="download" onClick={onDownloadClick} />
        </Typography>
      ),
      // @ts-ignore
      renderCell: ({ row }) => <UserCell {...row} />,
      // valueGetter is needed to generate the value that will be sent to the sortComparator
      valueGetter: (_value, row) => `${row?.firstName || '-'} ${row?.lastName || ''} ${row?.email || ''}`.trim(),
      sortComparator: (u1, u2) => u1.localeCompare(u2)
    },
    {
      field: '',
      headerName: '',
      minWidth: 160,
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Tooltip title={addUserButtonTooltip} arrow>
          <Button variant="contained" onClick={onAddUserClick}>
            Add User
          </Button>
        </Tooltip>
      ),
      type: 'actions',
      getActions: ({ id }) => [
        <IconButton
          type="edit"
          key={`lobs-edit-button-${id}`}
          onClick={() => {
            $$resellerUserDialog.open({
              title: `Edit User`,
              data: {
                ...pick(
                  dataGridProps.rows.find(user => user.id === id),
                  ['email', 'firstName', 'lastName']
                ),
                userId: Number(id)
              },
              onSubmit: onEditSubmitted,
              editMode: true
            });
          }}
        />,
        <IconButton type="delete" key={`lobs-delete-button-${id}`} onClick={() => onDeleteClicked(id)} />
      ]
    }
  ];
};
