import { Container, Typography } from '@mui/material';
import BatchEditVersionDatesDialog from '../../../components/BatchEditVersionDatesDialog/BatchEditVersionDatesDialog';
import BatchEditVersionNameDialog from '../../../components/BatchEditVersionNameDialog/BatchEditVersionNameDialog';
import CampaignVersionsDialog from '../../../components/CampaignVersionsDialog/CampaignVersionsDialog';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import SnackbarRoot from '../../../components/SnackbarRoot/SnackbarRoot';
import TutorialVideoButton from '../../../components/TutorialVideosModal/TutorialVideoButton';
import { $$tutorialVideos } from '../../../components/TutorialVideosModal/model';
import CampaignTableFilters from './CampaignTableFilters';
import CampaignsTable from './CampaignsTable';

/**
 * Component for displaying the form to manage campaigns.
 *
 * @returns {React.ReactNode} AdminCampaigns component
 */
export default function AdminCampaignsPage() {
  return (
    <>
      <CampaignVersionsDialog />
      <ConfirmationDialog />
      <BatchEditVersionNameDialog />
      <BatchEditVersionDatesDialog />
      <TutorialVideoButton url={$$tutorialVideos.links['ST_ADMIN:EDIT_CAMPAIGNS_VERSIONS']} />

      <Container id="admin-campaigns-container">
        <Typography variant="h4" color="text.primary" sx={{ mb: 6 }}>
          Edit Campaigns
        </Typography>
        <CampaignTableFilters />
        <CampaignsTable />
        <SnackbarRoot snackbarProps={{ autoHideDuration: 3000 }} />
      </Container>
    </>
  );
}

AdminCampaignsPage.propTypes = {};
