// @ts-ignore
import { Autocomplete, textFieldVariants } from '@ghs/components';
import { Cancel, DeleteForever, Edit, Save } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { DataGridPro, GridRowEditStopReasons, GridRowModes, gridClasses } from '@mui/x-data-grid-pro';
import { useUnit } from 'effector-react';
import { EditableStringCell } from '../../../components/CampaignVersionsDialog/EditableStringCell';
import { DataGridStylesWithoutSelectionOverride } from '../../../util/DataGridStyles';
import { $$adminCustomersPage } from './model';

/**
 * AdminCustomerLobsTable
 *
 * @returns {React.ReactNode} - JSX Element
 */
export default function AdminCustomerLobsTable() {
  const dataGridProps = $$adminCustomersPage.lobs.$$table.useDataGrid();

  const filterValue = useUnit($$adminCustomersPage.lobs.$filter);
  const highlightedRows = useUnit($$adminCustomersPage.lobs.$$table.$highlightedRows);
  const rowModesModel = useUnit($$adminCustomersPage.lobs.$$table.$rowModesModel);
  const errors = useUnit($$adminCustomersPage.lobs.$$table.$errors);
  const unsavedChanges = useUnit($$adminCustomersPage.lobs.$$table.$unsavedChanges);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  /** @type {import('@mui/x-data-grid-pro').GridColDef<Reseller.LineOfBusiness, any, any>[]} */
  const lobTableColumns = [
    {
      field: 'name',
      headerName: 'Line of Business',
      editable: true,
      minWidth: 160,
      flex: 1,
      renderEditCell: params => <EditableStringCell {...params} $$table={$$adminCustomersPage.lobs.$$table} />
    },
    {
      field: 'actions',
      type: 'actions',
      minWidth: 100,
      headerName: 'Actions',
      headerAlign: 'center',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const lob = dataGridProps.rows.find(c => c.id === id);
        const isCellWithError = errors.some(e => e.id === id);
        const isRowHasUnsavedChanges = unsavedChanges.find(r => r.id === id);
        if (!lob) {
          return [];
        }
        return isInEditMode
          ? [
              <Tooltip key={`lob-save-button-${id}`} title={!isCellWithError && (isRowHasUnsavedChanges ? 'Save' : 'No changes to save')} arrow>
                <span>
                  <IconButton key={`lob-save-button-${id}`} disabled={isCellWithError || !isRowHasUnsavedChanges} onClick={() => $$adminCustomersPage.lobs.saveClicked(id)}>
                    <Save fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>,
              <IconButton key={`lob-cancel-button-${id}`} onClick={() => $$adminCustomersPage.lobs.cancelClicked(id)}>
                <Cancel fontSize="small" />
              </IconButton>
            ]
          : [
              <IconButton key={`lob-edit-button-${id}`} onClick={() => $$adminCustomersPage.lobs.editClicked(id)}>
                <Edit fontSize="small" color="primary" />
              </IconButton>,
              <Tooltip key={`lob-delete-button-${id}`} title="Delete" arrow onClick={() => $$adminCustomersPage.lobs.deleteClicked(id)}>
                <IconButton key={`lob-delete-button-${id}`} sx={{ '&.Mui-disabled': { pointerEvents: 'auto' } }}>
                  <DeleteForever fontSize="small" color="warning" />
                </IconButton>
              </Tooltip>
            ];
      }
    }
  ];

  return (
    <Stack sx={{ flex: 1, gap: 2 }}>
      <Autocomplete
        id="lobs-batch-actions-autocomplete"
        label="Search Line of Business"
        placeholder="Select"
        options={dataGridProps.rows.map(row => ({ id: row.id, label: row.name }))}
        onChange={(_, value) => $$adminCustomersPage.lobs.filterSelected(value?.id ?? null)}
        variant={textFieldVariants.OUTLINED}
        value={dataGridProps.rows.find(row => row.id === filterValue)?.name || ''}
      />
      <DataGridPro
        data-testid="lobs-datagrid"
        {...dataGridProps}
        key={`lob-table-${dataGridProps.rows.map(row => row.id).join('-')}`}
        editMode="row"
        autoHeight
        rows={dataGridProps.rows.filter(row => (filterValue !== null ? row.id === filterValue : true))}
        columns={lobTableColumns}
        onRowEditStop={handleRowEditStop}
        initialState={{
          columns: { columnVisibilityModel: { id: false } },
          pagination: { paginationModel: { pageSize: 10 } }
        }}
        pagination
        pageSizeOptions={[5, 10, 25, 50]}
        autosizeOnMount
        autosizeOptions={{
          includeOutliers: true,
          includeHeaders: true
        }}
        getCellClassName={params =>
          Object.entries({
            [`${gridClasses.cell}--error`]: Boolean(errors.find(e => e.id === params.id && e.field === params.field)),
            'editable-date-cell': rowModesModel[params.row.id]?.mode === 'edit' && params.isEditable === true,
            'editable-date': rowModesModel[params.row.id]?.mode === 'edit' && params.colDef.type === 'date',
            // [`${gridClasses.cell}--edited`]: unsavedChanges.find(r => r.id === params.row.id && r.field === params.field),
            [`${gridClasses.cell}--success`]: highlightedRows.includes(params.row.id)
          })
            .filter(([, value]) => value)
            .map(([key]) => key)
            .slice(0, 1)
            .join(' ')
        }
        sx={DataGridStylesWithoutSelectionOverride}
        slots={{
          noRowsOverlay: NoRowsOverlay
        }}
      />
    </Stack>
  );
}

/**
 * NoRowsOverlay
 *
 * @returns {React.ReactNode} - JSX Element
 */
function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <span>Please select a customer name to display the</span>
      <span>Lines of Business associated with it.</span>
    </Stack>
  );
}
