import { Children } from 'react';
import PropTypes from 'prop-types';
import { propOr, __ } from 'ramda';

import {
  Select as MuiSelect,
  FormControl,
  InputLabel,
  FormHelperText,
  Box
} from '@mui/material';
import { red } from '@mui/material/colors';

export const selectVariants = {
  FILLED: 'filled',
  OUTLINED: 'outlined'
};

export const selectSizes = {
  SMALL: 'small',
  MEDIUM: 'medium'
};

const Select = ({
  children,
  variant,
  id,
  label,
  helperText,
  value,
  error,
  disabled,
  small,
  emptyText,
  icon,
  onChange,
  formSx,
  sx,
  ...overrides
}) => {
  const labelId = `${id}-label`;

  const valueLabelMap = Children.toArray(children).reduce(
    (acc, { props: { value: childValue, children: childChildren } }) => ({
      ...acc,
      [childValue]: childChildren
    }),
    {}
  );

  const getRenderedValue = propOr(emptyText, __, valueLabelMap);

  return (
    <FormControl
      variant={variant}
      error={error}
      disabled={disabled}
      sx={formSx}
    >
      <InputLabel
        shrink
        id={labelId}
        sx={{
          // fix a contrast error
          '&.Mui-error': { color: red[900] }
        }}
      >
        {label}
      </InputLabel>
      <MuiSelect
        id={id}
        labelId={labelId}
        label={label}
        value={value}
        onChange={onChange}
        size={small ? selectSizes.SMALL : selectSizes.MEDIUM}
        displayEmpty
        renderValue={val =>
          icon ? (
            <Box sx={{ display: 'flex' }}>
              {icon}
              <Box sx={{ ml: 1 }}>{getRenderedValue(val)}</Box>
            </Box>
          ) : (
            getRenderedValue(val)
          )
        }
        sx={{
          // fix a render bug for outlined labels
          '& legend': { maxWidth: '100%' },
          // disable hover/focus bg colors
          '&.MuiFilledInput-root': { backgroundColor: 'transparent' },
          [`& .MuiFilledInput-input`]: {
            bgcolor: 'misc.filledInputBg'
          },
          // thicker border on disabled state
          '&.Mui-disabled::before': {
            borderBottomWidth: 2
          },
          ...sx
        }}
        {...overrides}
      >
        {children}
      </MuiSelect>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};

Select.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(Object.values(selectVariants)),
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  emptyText: PropTypes.node,
  icon: PropTypes.node,
  formSx: PropTypes.shape(),
  sx: PropTypes.shape(),
  onChange: PropTypes.func
};

Select.defaultProps = {
  variant: selectVariants.FILLED,
  small: false,
  emptyText: 'Select',
  icon: null
};

export default Select;
