// @ts-ignore
import { buttonColors, dialogMaxWidths } from '@ghs/components';
import { createElement } from 'react';
import { $$confirmationDialog } from '../../../components/ConfirmationDialog/model';
import ConfirmationWithMemoryContent from '../../../components/ConfirmationWithMemoryContent/ConfirmationWithMemoryContent';

/**
 * @param {{
 *  lobUpdateConfirmationSkipChecked: import('effector').EventCallable<boolean>;
 *  onClose: () => void;
 *  onCancel: () => void;
 *  onAccept: () => void;
 * }} params - params
 * @returns {Parameters<typeof $$confirmationDialog['open']>[0]} - Confirmation dialog arg
 */
export const createLobUpdateConfirmationDialogArg = params => {
  const { lobUpdateConfirmationSkipChecked, onClose, onCancel, onAccept } = params;
  return {
    title: 'Update Line of Business information',
    content: createElement(ConfirmationWithMemoryContent, {
      title: 'Are you sure you want to update this LOB?',
      comment: 'If this LOB was created via RDS files, and the RDS files continue to contain the original info, the LOB will then be recreated in the system with subsequent campaigns within.',
      onValueChange: lobUpdateConfirmationSkipChecked,
      withNote: true
    }),
    onClose,
    buttons: [
      {
        children: 'Cancel',
        variant: /** @type {const} */ ('outlined'),
        onClick: () => {
          onCancel();
          $$confirmationDialog.close();
        }
      },
      {
        children: 'Confirm Update',
        variant: /** @type {const} */ ('contained'),
        onClick: () => {
          onAccept();
          $$confirmationDialog.close();
        }
      }
    ]
  };
};

/**
 * @param {{
 *  type: 'customer' | 'lob';
 *  onAccept: () => void;
 * }} params - params
 * @returns {Parameters<typeof $$confirmationDialog['open']>[0]} - Confirmation dialog arg
 */
export const createCustomerDeleteConfirmationDialogArg = params => {
  const { type, onAccept } = params;
  return {
    title: 'Wait!',
    content: type === 'customer' ? 'Deleting this Customer will also delete all Lines of Business and Campaigns within.' : 'Deleting this Line of Business will also delete all Campaigns within.',
    maxWidth: dialogMaxWidths.SM,
    buttons: [
      {
        children: type === 'customer' ? 'Keep Customer' : 'Keep Line of Business',
        onClick: () => $$confirmationDialog.close(),
        variant: /** @type {const} */ ('outlined')
      },
      {
        children: type === 'customer' ? 'Permanently delete customer' : 'Permanently delete line of business',
        onClick: () => {
          onAccept();
          $$confirmationDialog.close();
        },
        variant: /** @type {const} */ ('contained'),
        color: buttonColors.ERROR
      }
    ]
  };
};

/**
 * @param {{
 *  customerUpdateConfirmationSkipChecked: import('effector').EventCallable<boolean>;
 *  onClose: () => void;
 *  onCancel: () => void;
 *  onAccept: () => void;
 * }} params - params
 * @returns {Parameters<typeof $$confirmationDialog['open']>[0]} - Confirmation dialog arg
 */
export const createCustomerUpdateConfirmationDialogArg = params => {
  const { customerUpdateConfirmationSkipChecked, onClose, onCancel, onAccept } = params;
  return {
    title: 'Update customer information',
    content: createElement(ConfirmationWithMemoryContent, {
      title: 'Are you sure you want to update this customer?',
      comment:
        'If this Customer was created via RDS files, and the RDS files continue to contain the original info, the Customer will then be recreated in the system with subsequent campaigns within.',
      onValueChange: customerUpdateConfirmationSkipChecked,
      withNote: true
    }),
    onClose,
    buttons: [
      {
        children: 'Cancel',
        variant: /** @type {const} */ ('outlined'),
        onClick: () => {
          onCancel();
          $$confirmationDialog.close();
        }
      },
      {
        children: 'Confirm Update',
        variant: /** @type {const} */ ('contained'),
        onClick: () => {
          onAccept();
          $$confirmationDialog.close();
        }
      }
    ]
  };
};
