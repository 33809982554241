// @ts-ignore
import { Autocomplete, textFieldVariants } from '@ghs/components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import useDebounceValue from '../../hooks/useDebounceValue';
import { $$user } from '../../services/UserService/UserService';

/**
 * SelectUserAutocomplete component
 *
 * @param {{
 *  selectedUsers: { id: string; label: string }[];
 *  onSelectedUsersUpdated: (user: Users.User | null) => void;
 * } & Partial<import('@mui/material').AutocompleteProps>} props - props
 * @returns {React.ReactNode} SelectUserAutocomplete
 */
export default function SelectUserAutocomplete(props) {
  const { onSelectedUsersUpdated: onSelectedUsersUpdated, selectedUsers, ...rest } = props;

  const [inputValue, setInputValue] = useState(/** @type {string} */ (''));
  const [searchResults, setSearchResults] = useState(/** @type {Users.User[]} */ ([]));

  const debounceInputValue = useDebounceValue(inputValue, 300);

  useEffect(() => {
    $$user
      .getUsersFx({ page: 0, size: 100, search: debounceInputValue })
      .then(result => result.content)
      .catch(() => [])
      .then(setSearchResults);
  }, [debounceInputValue]);

  return (
    <Autocomplete
      {...rest}
      multiple
      limitTags={2}
      label="Search User Name or Email"
      placeholder="Select"
      options={searchResults.map(user => ({ id: user.userId, label: `${user.name} <${user.email}>` }))}
      onChange={(_, value) => {
          onSelectedUsersUpdated(value);
      }}
      onInputChange={e => {
        setInputValue(e?.target.value || '');
      }}
      variant={textFieldVariants.OUTLINED}
      value={selectedUsers}
    />
  );
}

SelectUserAutocomplete.propTypes = {
  onSelectedUsersUpdated: PropTypes.func.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.shape({}))
};
