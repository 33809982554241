// @ts-ignore
import { TextField } from '@ghs/components';
import { Tooltip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
dayjs.extend(customParseFormat);

const DataGridCellDatePicker = ({ name, value, isEditable, errorMessage, onChange, onClose, onEdit, sx, ...rest }) => {
  const [open, setOpen] = useState(false);

  return (
    <Tooltip
      disableHoverListener={false}
      title={errorMessage}
      arrow
      componentsProps={{ popper: { sx: { [`.MuiTooltip-tooltip`]: { background: theme => theme.palette.error.main } } }, arrow: { sx: { color: theme => theme.palette.error.main } } }}
    >
      <div>
        {isEditable ? (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              {...rest}
              value={dayjs(value)}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => {
                setOpen(false);
                onClose();
              }}
              onChange={newValue => {
                setOpen(false);
                onChange(newValue);
              }}
              sx={merge(sx, {
                '& .MuiOutlinedInput-root': {
                  paddingLeft: 1,
                  paddingRight: 1,
                  fontSize: '1em',
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  '& fieldset': { border: 'none' },
                  '& input': { marginLeft: 0, paddingLeft: 0 },
                  '& .MuiInputAdornment-positionEnd': { margin: 0, padding: 0 }
                }
              })}
            />
          </LocalizationProvider>
        ) : (
          <TextField
            name={name}
            variant="outlined"
            value={dayjs(value).format('MM/DD/YYYY')}
            InputProps={{
              style: { fontSize: 'inherit', display: 'flex', alignItems: 'center', height: '100%' },
              onDoubleClick: () => {
                setOpen(true);
                onEdit();
              }
            }}
            sx={{
              '& fieldset': { border: 'none' }
            }}
          />
        )}
      </div>
    </Tooltip>
  );
};

DataGridCellDatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date),
  isEditable: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  sx: PropTypes.object
};

export default DataGridCellDatePicker;
