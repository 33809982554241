// @ts-ignore
import { Autocomplete, textFieldVariants } from '@ghs/components';
import { Grid } from '@mui/material';
import { useUnit } from 'effector-react';
import { DelayedTextField } from '../../../components/DelayedTextField';
import { $$adminCampaignsPage } from './model';
import { DEFAULT_MAIL_DATE_FILTER_ID, mailDateFilters } from './utils';

/**
 * CampaignTableFilters
 *
 * @returns {React.ReactNode} - JSX Element
 */
export default function CampaignTableFilters() {
  const filters = useUnit($$adminCampaignsPage.$filters);
  const customers = useUnit($$adminCampaignsPage.$customers).map(customer => ({ id: customer.id, label: customer.name }));
  const linesOfBusiness = useUnit($$adminCampaignsPage.$linesOfBusiness).map(lob => ({ id: lob.id, label: lob.name }));

  return (
    <Grid container spacing={2} sx={{ alignItems: 'center', mb: 2 }}>
      <Grid item xs={12} md={3}>
        <DelayedTextField
          id="campaign-search-filter"
          type="search"
          placeholder="Search"
          label="Search"
          value={filters.search}
          onChange={value => $$adminCampaignsPage.setFilterByKey({ key: 'search', value })}
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Autocomplete
          id="campaign-maildate-filter"
          label="Mail Date"
          placeholder="Select"
          options={mailDateFilters}
          variant={textFieldVariants.OUTLINED}
          sx={{ width: '100%' }}
          name="mailDateFilter"
          value={mailDateFilters.find(filter => filter.id === (filters.mailDate || DEFAULT_MAIL_DATE_FILTER_ID)) || null}
          onChange={(_event, value) => {
            $$adminCampaignsPage.setFilterByKey({ key: 'mailDate', value: value?.id || null });
            /** @type {HTMLInputElement | undefined} */ (document.activeElement)?.blur();
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Autocomplete
          id="campaign-customer-filter"
          label="Customer"
          placeholder="Select"
          options={customers}
          variant={textFieldVariants.OUTLINED}
          sx={{ width: '100%' }}
          name="customer"
          value={filters.customerId ? customers.find(customer => customer.id === Number(filters.customerId)) || null : null}
          onChange={(_event, value) => $$adminCampaignsPage.setFilterByKey({ key: 'customerId', value: value?.id || null })}
          getOptionKey={option => option.id}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Autocomplete
          id="campaign-lob-filter"
          label="Line of Business"
          placeholder="Select"
          options={linesOfBusiness}
          variant={textFieldVariants.OUTLINED}
          sx={{ width: '100%' }}
          value={filters.lineOfBusinessIds ? linesOfBusiness.find(lob => lob.id === Number(filters.lineOfBusinessIds)) || null : null}
          onChange={(_event, value) => $$adminCampaignsPage.setFilterByKey({ key: 'lineOfBusinessIds', value: value?.id || null })}
        />
      </Grid>
    </Grid>
  );
}
