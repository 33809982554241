// @ts-ignore
import { dialogMaxWidths } from '@ghs/components';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import { $$confirmationDialog } from './model';

/**
 * ConfirmationDialog
 *
 * @returns {React.ReactNode} - JSX Element
 */
export default function ConfirmationDialog() {
  const open = useUnit($$confirmationDialog.$isOpen);
  const state = useUnit($$confirmationDialog.$state);
  const disabled = useUnit($$confirmationDialog.$isDisabled);

  if (!state) {
    return null;
  }

  const { title, content, onAccept = () => {}, acceptButtonColor, acceptButtonText, maxWidth, buttons, onClose = () => {} } = state;

  const buttonsElement = buttons ? (
    buttons.map((params, index) => <Button key={index + JSON.stringify(params.children)} {...params} />)
  ) : (
    <>
      <Button color="primary" variant="outlined" onClick={() => $$confirmationDialog.close()} disabled={disabled}>
        Cancel
      </Button>
      <Button
        // @ts-ignore
        color={acceptButtonColor || 'primary'}
        disabled={disabled}
        variant="contained"
        onClick={async () => {
          const promiseOrValue = onAccept();
          if (promiseOrValue instanceof Promise) {
            $$confirmationDialog.setDisabled();
            await promiseOrValue;
          }
          onClose();
          $$confirmationDialog.close();
        }}
      >
        {acceptButtonText || 'Ok'}
      </Button>
    </>
  );

  return (
    <Dialog
      id="confirmation-dialog"
      data-testid="confirmation-dialog"
      open={open}
      fullWidth={true}
      maxWidth={maxWidth || dialogMaxWidths.XS}
      onClose={() => {
        onClose();
        $$confirmationDialog.close();
      }}
      PaperProps={{ sx: { borderRadius: 2, padding: 2 } }}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">{title}</Typography>
        <IconButton
          onClick={() => {
            onClose();
            $$confirmationDialog.close();
          }}
          sx={{ height: 'min-content' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        <DialogActions sx={{ mt: 2 }}>{buttonsElement}</DialogActions>
      </DialogContent>
    </Dialog>
  );
}
