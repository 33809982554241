import { Box, Container, Stack, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import ResellerUserDialog from '../../../components/ResellerUserDialog/ResellerUserDialog';
import SnackbarRoot from '../../../components/SnackbarRoot/SnackbarRoot';
import { $$tutorialVideos } from '../../../components/TutorialVideosModal/model';
import TutorialVideoButton from '../../../components/TutorialVideosModal/TutorialVideoButton';
import useMountedEvent from '../../../hooks/useMountedEvent';
import { $$user, PERMISSIONS } from '../../../services/UserService/UserService';
import CustomersTable from './CustomersTable';
import CustomersUsersTable from './CustomersUsersTable';
import LinesOfBusinessTable from './LinesOfBusinessTable';
import LinesOfBusinessUsersTable from './LinesOfBusinessUsersTable';
import { $$adminDataAccessPage } from './model';

/**
 * AdminDataAccessPage
 *
 * @returns {React.ReactNode} AdminDataAccessPage
 */
export default function AdminDataAccessPage() {
  useMountedEvent($$adminDataAccessPage.pageMounted);
  const selectedCustomerId = useUnit($$adminDataAccessPage.$selectedCustomerId);
  const selectedLobId = useUnit($$adminDataAccessPage.$selectedLobId);
  const permissions = useUnit($$user.$permissions);

  return (
    <>
      <ResellerUserDialog />
      <ConfirmationDialog />
      <SnackbarRoot snackbarProps={{ autoHideDuration: 3000 }} />
      <TutorialVideoButton url={$$tutorialVideos.links['ST_ADMIN:MANAGE_USERS_DATA_ACCESS']} />

      <Container id="admin-data-access-container">
        <Stack direction={'row'} sx={{ gap: 4, mb: 2 }}>
          <Typography variant="h4" color="text.primary" sx={{ mb: 6 }}>
            Manage Users
          </Typography>
        </Stack>
        {permissions.includes(PERMISSIONS.RESELLER_ADMIN.MANAGE_USERS_DATA_ACCESS_CUSTOMER) && (
          <Stack sx={{ gap: 4, mb: 3, flexDirection: 'row' }}>
            <Box sx={{ flex: 1 }}>
              <CustomersTable />
            </Box>
            <Box sx={{ flex: 1 }}>{selectedCustomerId !== null && <CustomersUsersTable />}</Box>
          </Stack>
        )}
        {permissions.includes(PERMISSIONS.RESELLER_ADMIN.MANAGE_USERS_DATA_ACCESS_LOB) && (
          <Stack sx={{ gap: 4, mb: 3, flexDirection: 'row' }}>
            <Box sx={{ flex: 1 }}>{selectedCustomerId !== null && <LinesOfBusinessTable />}</Box>
            <Box sx={{ flex: 1 }}>{selectedCustomerId !== null && selectedLobId !== null && <LinesOfBusinessUsersTable />}</Box>
          </Stack>
        )}
      </Container>
    </>
  );
}
