import { Container, Stack, Typography } from '@mui/material';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import SnackbarRoot from '../../../components/SnackbarRoot/SnackbarRoot';
import { $$tutorialVideos } from '../../../components/TutorialVideosModal/model';
import TutorialVideoButton from '../../../components/TutorialVideosModal/TutorialVideoButton';
import AdminCustomerLobsTable from './AdminCustomerLobsTable';
import AdminCustomersTable from './AdminCustomersTable';

/**
 * Component for displaying the form to manage customers and lines of business.
 *
 * @returns {React.ReactNode} AdminCustomers component
 */
export default function AdminCustomersPage() {
  return (
    <>
      <ConfirmationDialog />
      <TutorialVideoButton url={$$tutorialVideos.links['ST_ADMIN:EDIT_CUSTOMER_LOB']} />

      <Container id="admin-customers-container">
        <Typography variant="h4" color="text.primary" sx={{ mb: 6 }}>
          Edit Customer/Line of Business
        </Typography>
        <Stack direction="row" sx={{ gap: 2 }}>
          <AdminCustomersTable />
          <AdminCustomerLobsTable />
        </Stack>
        <SnackbarRoot snackbarProps={{ autoHideDuration: 3000 }} />
      </Container>
    </>
  );
}
