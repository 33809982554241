import { useSearchParams } from 'react-router-dom';
import useAuth0WithErrorHandling from '../../hooks/useAuth0WithErrorHandling';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Link } from '@ghs/components';
import { fetchVerifyAQ } from '../../services/DataService';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { AddressInput } from './AddressInput';
import { SubmittedResults } from './SubmittedResults';
import { OutputAccordion } from './OutputDetails';
import { areObjectStringValuesEmpty, getSearchParamOrDefault, getStateSearchParamWithValidationOrDefault } from '../../util/FormUtil';
import { parseJSONWithFallback } from '../../util/JsonUtils';
import TutorialVideoButton from '../../components/TutorialVideosModal/TutorialVideoButton';
import { $$tutorialVideos } from '../../components/TutorialVideosModal/model';
import { useUnit } from 'effector-react';
import { $$contactService } from '../../services/ContactService/model';
import { generateErrorAlertWithContact } from '../../util/ErrorUtils';

const defaultFormData = {
  company: '',
  street: '',
  street2: '',
  urbanization: '',
  city: '',
  state: '',
  zip: '',
  zipPlusFour: '',
  standardizeStreets: true,
  useMixedCase: false,
  retainAddressLocation: true,
  suiteLinkToAddress2: true
};

const defaultSearchResults = {
  barCode: '',
  crrt: '',
  city: '',
  company: '',
  congressionalDistrict: '',
  county: '',
  countyNumber: '',
  dpv: '',
  dpvCode: '',
  dpvDescription: '',
  deliveryPoint: '',
  houseNumber: '',
  highriseDefault: false,
  ruralRouteDefault: false,
  lacs: '',
  lineOfTravel: '',
  lineOfTravelDirection: '',
  lookupReturnCode: '',
  lookupReturnDescription: '',
  pmb: '',
  postDirectional: '',
  preDirectional: '',
  rdi: '',
  recordType: '',
  secondaryUnitDesignator: '',
  state: '',
  street: '',
  street2: '',
  streetName: '',
  streetSuffix: '',
  suite: '',
  unitNumber: '',
  urbanization: '',
  zip: '',
  zipPlusFour: ''
};

const AddressVerification = () => {
  const { user } = useAuth0();
  const contactInfo = useUnit($$contactService.$contactInfo);
  const { getAccessTokenSilently } = useAuth0WithErrorHandling();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchResults, setSearchResults] = useState(defaultSearchResults);
  const [isRequestError, setIsRequestError] = useState(null);

  const advancedOptionsState = parseJSONWithFallback(localStorage.getItem('advanced-options-state'), {});

  const getUpdatedFormData = () => ({
    ...defaultFormData,
    company: getSearchParamOrDefault(searchParams, 'company', ''),
    street: getSearchParamOrDefault(searchParams, 'street', ''),
    street2: getSearchParamOrDefault(searchParams, 'street2', ''),
    urbanization: getSearchParamOrDefault(searchParams, 'urbanization', ''),
    city: getSearchParamOrDefault(searchParams, 'city', ''),
    state: getStateSearchParamWithValidationOrDefault(searchParams, 'state', ''),
    zip: getSearchParamOrDefault(searchParams, 'zip', ''),
    zipPlusFour: getSearchParamOrDefault(searchParams, 'zipPlusFour', ''),
    useMixedCase: getSearchParamOrDefault(searchParams, 'useMixedCase', advancedOptionsState?.useMixedCase !== undefined ? advancedOptionsState.useMixedCase : false),
    retainAddressLocation: getSearchParamOrDefault(
      searchParams,
      'retainAddressLocation',
      advancedOptionsState?.retainAddressLocation !== undefined ? advancedOptionsState.retainAddressLocation : true
    ),
    suiteLinkToAddress2: getSearchParamOrDefault(searchParams, 'suiteLinkToAddress2', advancedOptionsState?.suiteLinkToAddress2 !== undefined ? advancedOptionsState.suiteLinkToAddress2 : true)
  });

  const [formData, setFormData] = useState(getUpdatedFormData());

  useEffect(() => {
    const updatedFormData = getUpdatedFormData();
    const makeRequest = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const result = await fetchVerifyAQ(updatedFormData, accessToken);
        setIsRequestError(null);
        setSearchResults(result);
      } catch (err) {
        setIsRequestError(
          generateErrorAlertWithContact({
            contactInfo: contactInfo,
            userEmail: user?.email,
            requestId: err?.response?.headers?.get('Request-Id'),
            errorMessage: 'There was an error loading your requested address. Please retry your request'
          })
        );
      } finally {
        setFormData({
          ...defaultFormData,
          useMixedCase: updatedFormData.useMixedCase,
          retainAddressLocation: updatedFormData.retainAddressLocation,
          suiteLinkToAddress2: updatedFormData.suiteLinkToAddress2
        });
      }
    };

    if (!areObjectStringValuesEmpty(updatedFormData)) {
      makeRequest();
    }
  }, [searchParams]);

  return (
    <div id="address-verification">
      <TutorialVideoButton url={$$tutorialVideos.links['ADDRESS_VERIFICATION']} />

      <Collapse in={!!isRequestError && (!!isRequestError.title || !!isRequestError.message)}>
        <Alert
          severity={isRequestError?.severity}
          action={
            <IconButton
              onClick={() =>
                setIsRequestError({
                  ...isRequestError,
                  ...{
                    title: '',
                    message: ''
                  }
                })
              }
            >
              <CloseIcon />
            </IconButton>
          }
        >
          <Typography variant="h6">{isRequestError?.title}</Typography>
          <Typography>{isRequestError?.message}</Typography>
        </Alert>
      </Collapse>
      <Grid container alignItems={'flex-start'} spacing={3} marginTop={!!isRequestError && (!!isRequestError.title || !!isRequestError.message) ? 2 : undefined}>
        <Grid container item spacing={2} md={12} xl={6}>
          <Stack direction={'column'} spacing={2}>
            <AddressInput formData={formData} setSearchParams={setSearchParams} />
            <SubmittedResults searchResults={searchResults} searchParams={searchParams} formData={formData} isRequestError={isRequestError} setFormData={setFormData} />
          </Stack>
        </Grid>
        <Grid container item spacing={2} md={12} xl={6}>
          <OutputAccordion searchResults={searchResults} isRequestError={isRequestError} />
        </Grid>
        <Grid container item spacing={2} xs={12}>
          <Box
            component="footer"
            sx={{
              backgroundColor: 'primary.dark',
              color: 'primary.contrastText',
              padding: 1,
              textAlign: 'center',
              position: 'fixed',
              bottom: 0,
              width: '100%',
              marginLeft: -1
            }}
          >
            <Typography variant="body2" sx={{ fontStyle: 'italic', paddingRight: 9 }}>
              Did you know that Address Verification is powered by our VerifyAQ API? Feel free to review the{' '}
              <Link
                sx={{ color: 'primary.contrastText', textDecoration: 'underline' }}
                href="https://docs.grayhairsoftware.com/docs/verifyaq/lcmoj0ofwkbz2-verify-address"
                target="_blank"
                rel="noopener noreferrer"
              >
                documentation
              </Link>{' '}
              and reach out to{' '}
              {contactInfo?.email ? (
                <Link sx={{ color: 'primary.contrastText', textDecoration: 'underline' }} href={`mailto:` + contactInfo.email}>
                  {contactInfo.email}
                </Link>
              ) : (
                'your tracking supplier'
              )}{' '}
              for subscription details.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddressVerification;
