import { $$reseller } from "../../../services/ResellerService/model";
import { $$snackbar } from '../../../components/SnackbarRoot/model.js';
import { $$confirmationDialog } from '../../../components/ConfirmationDialog/model.js';
import { attach, createEvent, createStore, sample } from "effector";
import { combineEvents, spread } from "patronum";
import { buttonColors } from "@ghs/components";
import DeleteInHomeWindowConfirmationContent from "./DeleteInHomeWindowConfirmationContent";
import { createElement } from 'react';

//-------------------------------- Stores --------------------------------
const $defaultInHomeWindows = createStore([]);
const $allInHomeWindows = createStore([]);
const $filteredInHomeWindows = createStore([]);
const $customers = createStore([]);
const $selectedCustomer = createStore(null);
const $lobs = createStore([]);
const $selectedLob = createStore(null);
const $mailClasses = createStore([
  { id: 1, label: "First" },
  { id: 2, label: "Marketing" },
  { id: 3, label: "Periodicals" }
]);
const $selectedMailClass = createStore(null);
const $inHomeStart = createStore(''); // If init value is null, text field is not reset when calling $inHomeStart.reinit
const $inHomeEnd = createStore(''); // If init value is null, text field is not reset when calling $inHomeEnd.reinit
const $inHomeStartError = createStore(null);
const $inHomeEndError = createStore(null);
const $recalculate = createStore(true);
const $isFormValid = createStore(false);
const $isDeleteEnabled = createStore(false);

//-------------------------------- Events --------------------------------
const tableMounted = createEvent();
const customerSelected = createEvent();
const lobSelected = createEvent();
const mailClassSelected = createEvent();
const updateFilteredInHomeWindows = createEvent();
const inHomeStartChanged = createEvent();
const inHomeEndChanged = createEvent();
const recalculateChanged = createEvent();
const deleteClicked = createEvent();
const saveClicked = createEvent();
const inRowDeleteClicked = createEvent();
const inRowEditClicked = createEvent();
const editDataReady = combineEvents({ row: inRowEditClicked, lobs: $lobs.updates });

// Wrap the effects to be able to handle the done event in each page separately and avoid overlaps
const getLineOfBusinessFx = attach({ effect: $$reseller.getLineOfBusinessFx});

const getInHomeValueError = value => {
  const valid = isValidInHomeValue(value)
  return value && !valid ? 'Please input a value from 1 - 99' : null;
}

const getInHomeValueEndError = value => {
  return parseInt(value) < parseInt($inHomeStart.getState()) ? 'In-Home END must be equal or greater than In-Home START' : null;
}

const isValidInHomeValue = value => {
  const isNum = /^\d+$/.test(value);
  const parsedValue = parseInt(value);
  return isNum && parsedValue > 0 && parsedValue < 100;
}

const isFormValid = () => {
  const validInHomeValues = isValidInHomeValue($inHomeStart.getState()) && isValidInHomeValue($inHomeEnd.getState()) && !$inHomeStartError.getState() && !$inHomeEndError.getState();
  return $selectedCustomer.getState() && $selectedMailClass.getState() && validInHomeValues;
}

const isDeleteEnabled = () => {
  return $selectedCustomer.getState() && $selectedMailClass.getState();
}

// Update $inHomeWindows when done fetching data
sample({
  clock: $$reseller.getInHomeWindowsFx.doneData,
  target: $allInHomeWindows
});

// Filter in home windows in memory according to selected filters
sample({
  source: {
    inHomeWindows: $allInHomeWindows,
    customer: $selectedCustomer,
    lob: $selectedLob,
    mailClass: $selectedMailClass
  },
  clock: updateFilteredInHomeWindows,
  target: $filteredInHomeWindows,
  fn: ( { inHomeWindows, customer, lob, mailClass }) => {
    return inHomeWindows.filter(row =>
        (!customer || row.customer?.id === customer.id) &&
        (!lob || row.lineOfBusiness?.id === lob.id) &&
        (!mailClass || row.mailClass?.id === mailClass.id)
    );
  }
});

sample({
  clock: [
    $selectedCustomer.updates,
    $selectedLob.updates,
    $selectedMailClass.updates,
    // When in home windows are reloaded from backend (That is, when an in home window is added/updated/deleted) the filtered ones need to be refreshed too
    $allInHomeWindows.updates
  ],
  target: updateFilteredInHomeWindows
});

// When the selected customer changes or an in home window is added/updated/deleted, reset the selected LOB
sample({
  clock: [
    $selectedCustomer.updates,
    $$reseller.updateLobInHomeWindowFx.doneData,
    $$reseller.updateCustomerInHomeWindowFx.doneData,
    $$reseller.deleteInHomeWindowFx.doneData
  ],
  target: [
    $selectedLob.reinit,
    $selectedMailClass.reinit,
    $inHomeStart.reinit,
    $inHomeEnd.reinit
  ]
});

// When the selected customer changes, fetch the LOBs for that customer
sample({
  source: $selectedCustomer,
  clock: $selectedCustomer.updates,
  filter: customer => customer,
  fn: customer => ({ customerIds: customer.id }),
  target: getLineOfBusinessFx
});

// Load the in home windows when the table is mounted and after saves and deletes
sample({
  clock: [
    $$reseller.updateLobInHomeWindowFx.doneData,
    $$reseller.updateCustomerInHomeWindowFx.doneData,
    $$reseller.deleteInHomeWindowFx.doneData,
    tableMounted.filter({ fn: Boolean }).map(() => ({}))
  ],
  target: $$reseller.getInHomeWindowsFx
});

// When the table is mounted, load the default in home windows
sample({
  clock: tableMounted.filter({ fn: Boolean }).map(() => ({})),
  target: $$reseller.getDefaultInHomeWindowsFx
});

sample({
  clock: $$reseller.getDefaultInHomeWindowsFx.doneData,
  target: $defaultInHomeWindows
})

// When the table is mounted, load the customers
sample({
  clock: tableMounted.filter({ fn: Boolean }).map(() => ({})),
  target: $$reseller.getCustomersFx
});

// Update the customer options when the call to the backend is finished
sample({
  clock: $$reseller.getCustomersFx.doneData,
  fn: customers => customers.map(customer => ({ id: customer.id, label: customer.name })).sort((a, b) => a.label.localeCompare(b.label)),
  target: $customers
});

// When a customer is selected, update the $selectedCustomer value
sample({
  clock: customerSelected,
  target: $selectedCustomer
})

// Update the LOBs options when the call to the backend is finished
sample({
  clock: getLineOfBusinessFx.doneData,
  fn: lobs => lobs.map(lob => ({ id: lob.id, label: lob.name })).sort((a, b) => a.label.localeCompare(b.label)),
  target: $lobs
});

// When a LOB is selected, update the $selectedLob value
sample({
  clock: lobSelected,
  target: $selectedLob
})

// When a mail class is selected, update the $selectedMailClass value
sample({
  clock: mailClassSelected,
  target: $selectedMailClass
})

sample({
  clock: inHomeStartChanged,
  target: $inHomeStart
})

sample({
  clock: inHomeEndChanged,
  target: $inHomeEnd
})

sample({
  source: $inHomeStart,
  clock: $inHomeStart.updates,
  fn: getInHomeValueError,
  target: $inHomeStartError
})

sample({
  source: $inHomeEnd,
  clock: $inHomeEnd.updates,
  fn: (value) => getInHomeValueError(value) || getInHomeValueEndError(value),
  target: $inHomeEndError
})

sample({
  clock: recalculateChanged,
  target: $recalculate
})

// When the form changes it validates the form
sample({
  clock: [$selectedCustomer.updates, $selectedLob.updates, $selectedMailClass.updates, $inHomeStart.updates, $inHomeEnd.updates],
  fn: isFormValid,
  target: $isFormValid
})

// It determines whether the delete button should be enabled when the selected customer or mail class changes
sample({
  clock: [$selectedCustomer.updates, $selectedMailClass.updates],
  fn: isDeleteEnabled,
  target: $isDeleteEnabled
})

sample({
  clock: deleteClicked,
  fn: () => {
    const body = {
      mailClass: $selectedMailClass.getState().id,
      customerId: $selectedCustomer.getState().id,
      lineOfBusinessId: $selectedLob.getState()?.id
    };
    return {
      title: 'Confirm In-Home Delete',
      content: createElement(DeleteInHomeWindowConfirmationContent, { customer: $selectedCustomer.getState().label, lineOfBusiness: $selectedLob.getState()?.label, mailClass: $selectedMailClass.getState().label }),
      onAccept: () => $$reseller.deleteInHomeWindowFx(body),
      acceptButtonText: 'Delete',
      acceptButtonColor: buttonColors.ERROR
    }
  },
  target: $$confirmationDialog.open
})

sample({
  clock: inRowDeleteClicked,
  fn: row => {
    const body = { mailClass: row.mailClass.id, customerId: row.customer.id, lineOfBusinessId: row.lineOfBusiness?.id };
    return {
      title: 'Confirm In-Home Delete',
      content: createElement(DeleteInHomeWindowConfirmationContent, { customer: row.customer.name, lineOfBusiness: row.lineOfBusiness?.name, mailClass: row.mailClass.description }),
      onAccept: () => $$reseller.deleteInHomeWindowFx(body),
      acceptButtonText: 'Delete',
      acceptButtonColor: buttonColors.ERROR
    }
  },
  target: $$confirmationDialog.open
})

// When in row edit is clicked and the customer matches the selected customer, it triggers the editDataReady event
sample({
  clock: inRowEditClicked,
  filter: row => $selectedCustomer.getState()?.id === row.customer.id,
  fn: row => ({ row }),
  target: editDataReady
})

// When in row edit is clicked and the customer does not match the selected customer, triggers the customerSelected
// event so that the LOBs will be loaded
sample({
  clock: inRowEditClicked,
  filter: row => $selectedCustomer.getState()?.id !== row.customer.id,
  fn: row => $customers.getState().find(c => c.id === row.customer.id),
  target: customerSelected
})

// When the editDataReady event is triggered, it fills the form with the data from the row
sample({
  clock: editDataReady,
  fn: ({row}) => ({
      customer: $customers.getState().find(c => c.id === row.customer.id),
      lob: $lobs.getState().find(l => l.id === row.lineOfBusiness.id) || null,
      mailClass: $mailClasses.getState().find(m => m.id === row.mailClass.id),
      inHomeStart: row.inHomeStart,
      inHomeEnd: row.inHomeEnd
    }),
  target: spread({ customer: $selectedCustomer, lob: $selectedLob, mailClass: $selectedMailClass, inHomeStart: $inHomeStart, inHomeEnd: $inHomeEnd })
})

// If save is clicked with a LOB selected, save a LOB in home window
sample({
  clock: saveClicked,
  filter: () => $selectedLob.getState(),
  fn: () => ({
    mailClass: $selectedMailClass.getState().id,
    customerId: $selectedCustomer.getState().id,
    lineOfBusinessId: $selectedLob.getState().id,
    inHomeStart: $inHomeStart.getState(),
    inHomeEnd: $inHomeEnd.getState(),
    recalculateInHomeDates: $recalculate.getState()
  }),
  target: $$reseller.updateLobInHomeWindowFx
})

// If save is clicked without a LOB selected, save a customer in home window
sample({
  clock: saveClicked,
  filter: () => !$selectedLob.getState(),
  fn: () => ({
    mailClass: $selectedMailClass.getState().id,
    customerId: $selectedCustomer.getState().id,
    inHomeStart: $inHomeStart.getState(),
    inHomeEnd: $inHomeEnd.getState(),
    recalculateInHomeDates: $recalculate.getState()
  }),
  target: $$reseller.updateCustomerInHomeWindowFx
})

// Display alert when save succeeds
sample({
  clock: [ $$reseller.updateCustomerInHomeWindowFx.done, $$reseller.updateLobInHomeWindowFx.done ],
  fn: (response) => {
    const { params } = response;
    console.info(`In home window saved for ${JSON.stringify(params)}`);
    return { message: `In-Home window saved`, severity: 'success' };
  },
  target: $$snackbar.open
});

// Display error alert when save fails
sample({
  clock: [ $$reseller.updateCustomerInHomeWindowFx.fail, $$reseller.updateLobInHomeWindowFx.fail ],
  fn: (response) => {
    const { params, error } = response;
    console.error(`Failed to save in home window for ${JSON.stringify(params)}`);

    let message = `Error saving In-Home window`;
    let autoHideDuration = undefined;
    if (error?.response?.status === 409 && error.response?.data?.message) {
      message = error.response.data.message;
      autoHideDuration = 6000; // Conflict messages are quite long
    }

    return { message: message, severity: 'error', autoHideDuration: autoHideDuration };
  },
  target: $$snackbar.open
});

// Display error alert when delete fails
sample({
  clock: $$reseller.deleteInHomeWindowFx.fail,
  fn: (response) => {
    const { params } = response;
    console.error(`Failed to delete in home window for ${JSON.stringify(params)}`);
    return { message: params ? `Error deleting In-Home window` : 'Unexpected error', severity: 'error' };
  },
  target: $$snackbar.open
});

export const $$inHomeWindowsTable = {
  // Stores
  $defaultInHomeWindows,
  $filteredInHomeWindows,
  $customers,
  $selectedCustomer,
  $lobs,
  $selectedLob,
  $mailClasses,
  $selectedMailClass,
  $inHomeStart,
  $inHomeEnd,
  $inHomeStartError,
  $inHomeEndError,
  $recalculate,
  $isFormValid,
  $isDeleteEnabled,
  $isTableLoading: $$reseller.getInHomeWindowsFx.pending,
  $isInHomeInputEnabled: $selectedCustomer.getState() && $selectedLob.getState() && $selectedMailClass.getState(),

  // Events
  tableMounted,
  customerSelected,
  lobSelected,
  mailClassSelected,
  inHomeStartChanged,
  inHomeEndChanged,
  recalculateChanged,
  deleteClicked,
  saveClicked,
  inRowDeleteClicked,
  inRowEditClicked
};