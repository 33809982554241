// @ts-ignore
import { buttonColors } from '@ghs/components';
import { createElement } from 'react';
import { $$confirmationDialog } from '../ConfirmationDialog/model';
import ConfirmationWithMemoryContent from '../ConfirmationWithMemoryContent/ConfirmationWithMemoryContent';

type CreateDeleteConfirmationDialogArgParams = {
  roles: Roles.Role[];
  users: Users.User[];
  roleId: Roles.Role['id'];
  userId: Users.User['userId'];
  deleteConfirmationSkipChecked: import('effector').EventCallable<boolean>;
  deleteConfirmed: import('effector').EventCallable<Roles.Role['id']>;
};

export const createDeleteConfirmationDialogArg = (params: CreateDeleteConfirmationDialogArgParams): (typeof $$confirmationDialog)['$state']['__'] => {
  const { roles, users, roleId, userId, deleteConfirmationSkipChecked, deleteConfirmed } = params;
  return {
    title: `Remove ${roles.find(role => role.id === roleId)?.name} for ${users.find(user => user.userId === userId)?.name}`,
    content: createElement(ConfirmationWithMemoryContent, {
      title: 'Are you sure you want to remove this role?',
      comment: 'This will remove permissions for this user.',
      onValueChange: deleteConfirmationSkipChecked
    }),
    buttons: [
      {
        children: 'Cancel',
        variant: 'outlined',
        onClick: () => {
          $$confirmationDialog.close();
        }
      },
      {
        children: 'Remove role',
        variant: 'contained',
        color: buttonColors.ERROR,
        onClick: () => {
          deleteConfirmed(roleId);
          $$confirmationDialog.close();
        }
      }
    ]
  };
};
