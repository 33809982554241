// @ts-ignore
import { Autocomplete, textFieldVariants } from '@ghs/components';
import { Box, Button, CircularProgress, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import { keyBy } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { $$theme } from '../../services/ThemeService/model';
import { $$batchEditVersionDatesDialogModel } from '../BatchEditVersionDatesDialog/model';
import { $$batchEditVersionNameDialog } from '../BatchEditVersionNameDialog/model';
import { DelayedTextField } from '../DelayedTextField';
import { $$campaignVersionsDialog } from './model';

/**
 * CampaignVersionsFilters
 *
 * @typedef {import('@mui/x-data-grid-pro').GridRowId} GridRowId
 * @param {object} props - props
 * @param {boolean} props.hasUnsavedChanges - hasUnsavedChanges
 * @returns {React.ReactNode} - JSX Element
 */
export default function CampaignVersionsFilters(props) {
  const { hasUnsavedChanges } = props;

  const { palette } = useUnit($$theme.$palette);
  const campaignId = useUnit($$campaignVersionsDialog.$state.map(state => state?.campaignId || null));
  const versions = useUnit($$campaignVersionsDialog.$$versions.$data);
  const search = useUnit($$campaignVersionsDialog.$search);
  const isRecalculationEnabled = useUnit($$campaignVersionsDialog.$isRecalculationEnabled);
  const selectedRowIds = useUnit($$campaignVersionsDialog.$$versions.$selectedRowsIds);
  const selectedVersionWithScans = useUnit($$campaignVersionsDialog.$selectedVersionWithScans);
  const isValidationPending = useUnit($$campaignVersionsDialog.$isValidationPending);
  const isSavePending = useUnit($$campaignVersionsDialog.$isSavePending);
  const errors = useUnit($$campaignVersionsDialog.$$versions.$errors);

  useEffect(() => {
    if (selectedRowIds.length > 0) {
      document.getElementById('versions-batch-actions-autocomplete')?.focus();
    }
  }, [selectedRowIds.length]);

  return (
    <Stack sx={{ my: 2, gap: 2 }}>
      <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
        <DelayedTextField
          data-testid="versions-search"
          type="search"
          placeholder="Search"
          label="Search"
          value={search}
          onChange={value => $$campaignVersionsDialog.searchChanged(value)}
          sx={{ width: '100%', maxWidth: '36rem' }}
        />
        <Autocomplete
          id="versions-batch-actions-autocomplete"
          data-testid="versions-batch-actions-autocomplete"
          label="Batch action"
          placeholder="Select"
          disabled={selectedRowIds.length < 1}
          options={[
            {
              id: 0,
              label: 'Batch edit dates',
              onSelect: () => {
                if (campaignId && versions) {
                  $$batchEditVersionDatesDialogModel.open({
                    campaignId,
                    versions: selectedRowIds.map(id => keyBy(versions, 'id')[id])
                  });
                }
              }
            },
            {
              id: 1,
              label: 'Batch edit version name',
              onSelect: () => {
                if (campaignId && versions) {
                  $$batchEditVersionNameDialog.open({
                    campaignId,
                    versionNames: selectedRowIds.map(id => keyBy(versions, 'id')[id]).map(({ id, name }) => ({ id, name }))
                  });
                }
              }
            }
          ]}
          variant={textFieldVariants.OUTLINED}
          sx={{ width: '36rem', '.MuiOutlinedInput-notchedOutline': { border: `1.5px solid ${palette.primary.main}` }, legend: { color: palette.primary.main } }}
          value={null}
          onChange={(_, { onSelect }) => {
            onSelect();
            /** @type {HTMLInputElement | undefined} */ (document.activeElement)?.blur();
          }}
        />
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <Tooltip title="In-Home dates will be recalculated when Mail Dates are changed. GHS standard defaults will be used unless Client-specific defaults are available" arrow>
          <Box
            data-testid="recalculate-in-home-dates-toggle"
            sx={{ display: 'flex', alignItems: 'center', gap: 2, width: 'fit-content' }}
            onClick={() => $$campaignVersionsDialog.realculateToggleChanged(!isRecalculationEnabled)}
          >
            <Switch color="success" checked={isRecalculationEnabled} />
            <Typography sx={{ textTransform: 'uppercase', cursor: 'default' }} variant="subtitle2" color="primary">
              Recalculate in-home dates
            </Typography>
          </Box>
        </Tooltip>
        <Box sx={{ display: 'flex', gap: 2, textTransform: 'uppercase' }}>
          <Tooltip title={selectedVersionWithScans ? 'Selected version has scans and cannot be deleted' : null} arrow>
            <span>
              <Button
                data-testid="delete-selected"
                variant="outlined"
                color="error"
                disabled={selectedRowIds.length === 0 || selectedVersionWithScans}
                onClick={() => $$campaignVersionsDialog.deleteSelectedClicked()}
              >
                Delete selected
              </Button>
            </span>
          </Tooltip>
          <Button data-testid="discard-changes" variant="outlined" onClick={() => $$campaignVersionsDialog.discardClicked()} disabled={!hasUnsavedChanges}>
            Discard changes
          </Button>
          <Button
            data-testid="save-all-changes"
            variant="contained"
            onClick={() => $$campaignVersionsDialog.saveAllClicked()}
            disabled={!hasUnsavedChanges || errors.length > 0 || isValidationPending}
          >
            {isValidationPending || isSavePending ? <CircularProgress size={24} color="inherit" /> : 'Save all changes'}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}

CampaignVersionsFilters.propTypes = {
  hasUnsavedChanges: PropTypes.bool.isRequired
};
