import PropTypes from 'prop-types';
import * as React from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import TextField from '@mui/material/TextField';

const defaultValue = dayjs();

export const dateTimePickerTypes = {
  TIME: 'time',
  MOBILETIME: 'mobileTime',
  DESKTOPDATE: 'desktopDate',
  MOBILEDATE: 'mobileDate',
  DATETIME: 'datetime'
};

const DateTimePickerByType = (props) => {
  const { type, value, handleChange, ...overrides } = props;

  if (type === dateTimePickerTypes.DESKTOPDATE) {
    return (
      <DesktopDatePicker
        label="Date desktop"
        inputFormat="MM/DD/YYYY"
        value={dayjs(value)}
        onChange={handleChange}
        textField={params => <TextField {...params} />}
        {...overrides}
      />
    );
  }
  if (type === dateTimePickerTypes.MOBILEDATE) {
    return (
      <MobileDatePicker
        label="Date mobile"
        inputFormat="MM/DD/YYYY"
        value={dayjs(value)}
        onChange={handleChange}
        textField={params => <TextField {...params} />}
        {...overrides}
      />
    );
  }
  if (type === dateTimePickerTypes.TIME) {
    return (
      <TimePicker
        label="Time"
        value={dayjs(value)}
        onChange={handleChange}
        textField={params => <TextField {...params} />}
        {...overrides}
      />
    );
  }
  if (type === dateTimePickerTypes.MOBILETIME) {
    return (
      <MobileTimePicker
        label="Time"
        value={dayjs(value)}
        onChange={handleChange}
        textField={params => <TextField {...params} />}
        {...overrides}
      />
    );
  }
  return (
    <MuiDateTimePicker
      label="Date&Time picker"
      value={dayjs(value)}
      onChange={handleChange}
      textField={params => <TextField {...params} />}
      {...overrides}
    />
  );
};

const DateTimePicker = ({ type, ...overrides }) => {
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = newValue => {
    setValue(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePickerByType type={type} value={value} handleChange={handleChange} {...overrides} />
    </LocalizationProvider>
  );
};

DateTimePicker.propTypes = {
  type: PropTypes.oneOf(Object.values(dateTimePickerTypes))
};

export default DateTimePicker;
