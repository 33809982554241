import {$$reseller} from "../../../services/ResellerService/model";
import {
  $$logo,
  deleteLogoFx,
  fetchLogoCriteriaFx,
  listLogosFx,
  setCurrentLogoFx,
  uploadLogoSetFx
} from "../../../services/LogoService";
import {$$personaService} from "../../../services/PersonaService/model";
import {$$confirmationDialog} from '../../../components/ConfirmationDialog/model.js';
import {$$bannerService} from "../../../services/BannerService";
import {$$infoDialog} from "../../../components/InfoDialog/model";
import {attach, combine, createEvent, createStore, sample} from "effector";
import {spread} from "patronum";
import {buttonColors} from "@ghs/components";
import AdminLogosHelpDialogContent from "./AdminLogosHelpDialogContent";
import {$$snackbar} from "../../../components/SnackbarRoot/model";
import { VOID } from "../../../util/JsonUtils";

//-------------------------------- Stores --------------------------------
const $isResellerUser = sample({
  source: $$personaService.$activePersona,
  fn: persona => !persona?.customerId
})

const $logoCriteria = createStore(null);
const $customers = createStore([]);
const $selectedCustomer = createStore(null);
const $resellerLogos = createStore([]);
const $resellerSelectionModel = createStore([]);
const $resellerUploadDialogOpen = createStore(false);
const $resellerDeleteDisabled = createStore(false);
const $resellerActivateDisabled = createStore(false);
const $selectedResellerLogo = sample({
  source: {
    resellerSelectionModel: $resellerSelectionModel,
    resellerLogos: $resellerLogos
  },
  fn: ({ resellerSelectionModel, resellerLogos }) => resellerLogos.find(logo => logo.id === resellerSelectionModel[0]) || null
})
const $customerLogos = createStore([]);
const $customerSelectionModel = createStore([]);
const $customerUploadDialogOpen = createStore(false);
const $customerDeleteDisabled = createStore(false);
const $customerActivateDisabled = createStore(false);
const $selectedCustomerLogo = sample({
  source: {
    customerSelectionModel: $customerSelectionModel,
    customerLogos: $customerLogos
  },
  fn: ({ customerSelectionModel, customerLogos }) => customerLogos.find(logo => logo.id === customerSelectionModel[0]) || null
})
const $bannerOptIn = createStore(false);

//-------------------------------- Events --------------------------------
const tableMounted = createEvent();
const customerSelected = createEvent();
const resellerLogoSelected = createEvent();
const resellerTableUploadClicked = createEvent();
const resellerUploadDialogClosed = createEvent();
const resellerUploadDialogUploadClicked = createEvent();
const resellerDeleteClicked = createEvent();
const resellerActivateClicked = createEvent();
const resellerHelpClicked = createEvent();
const customerLogoSelected = createEvent();
const customerTableUploadClicked = createEvent();
const customerUploadDialogClosed = createEvent();
const customerUploadDialogUploadClicked = createEvent();
const customerDeleteClicked = createEvent();
const customerActivateClicked = createEvent();
const bannerOptInSwitchClicked = createEvent();
const customerHelpClicked = createEvent();

//-------------------------------- Effects --------------------------------
// Wrap effects used by resellers and customers to easily tell the difference
const listResellerLogosFx = attach({ effect: listLogosFx});
const deleteResellerLogoFx = attach({ effect: deleteLogoFx});
const uploadResellerLogoFx = attach({ effect: uploadLogoSetFx});
const setResellerLogoFx = attach({ effect: setCurrentLogoFx});
const listCustomerLogosFx = attach({ effect: listLogosFx});
const deleteCustomerLogoFx = attach({ effect: deleteLogoFx});
const uploadCustomerLogoFx = attach({ effect: uploadLogoSetFx});
const setCustomerLogoFx = attach({ effect: setCurrentLogoFx});

// When the table is mounted for a reseller user, load the customers for this reseller and the banner options
sample({
  source: $$personaService.$activePersona,
  clock: tableMounted.filter({ fn: Boolean }).map(() => ({})),
  filter: persona => !persona.customerId,
  fn: persona => ({ customers: {}, bannerOptions: { resellerId: persona?.ghsClientId } }),
  target: spread({ customers: $$reseller.getCustomersFx, bannerOptions: $$bannerService.getResellerBannerOptions })
});

// When the table is mounted, load the criteria/restrictions to upload logos
sample({
  clock: tableMounted.filter({ fn: Boolean }).map(() => ({})),
  target: fetchLogoCriteriaFx
});

// When the logo criteria data is received, store it
sample({
  clock: fetchLogoCriteriaFx.doneData,
  target: $logoCriteria
})

const $isMaxResellerLogosReached = sample({
  source: {
    logoCriteria: $logoCriteria,
    resellerLogos: $resellerLogos
  },
  fn: ({ logoCriteria, resellerLogos }) => {
    const validLogosCount = resellerLogos?.filter(logo => logo.name !== "current" && logo.name !== "GHS current").length;
    return (validLogosCount >= logoCriteria?.maxLogos);
  }
})

const $isMaxCustomerLogosReached = sample({
  source: {
    logoCriteria: $logoCriteria,
    customerLogos: $customerLogos
  },
  fn: ({ logoCriteria, customerLogos }) => {
    const validLogosCount = customerLogos?.filter(logo => logo.name !== "current").length;
    return (validLogosCount >= logoCriteria?.maxLogos);
  }
})

// When the table is mounted for a customer user, load the logos for the customer
sample({
  source: $$personaService.$activePersona,
  clock: tableMounted.filter({ fn: Boolean }).map(() => ({})),
  filter: persona => persona.customerId,
  fn: persona => ({ id: persona.customerId }),
  target: customerSelected
});

// When the customer data is received, store it
sample({
  clock: $$reseller.getCustomersFx.doneData,
  fn: customers => customers.map(customer => ({ id: customer.id, label: customer.name })).sort((a, b) => a.label.localeCompare(b.label) ),
  target: $customers
})

// When the table is mounted, load the logos for this reseller
sample({
  source: $$personaService.$activePersona,
  clock: tableMounted.filter({ fn: Boolean }).map(() => ({})),
  filter: persona => !persona.customerId,
  fn: (persona) => ({ resellerId: persona?.ghsClientId }),
  target: listResellerLogosFx
});

const addTimestampToCurrentLogo = logo => {
  const now = new Date().getTime();
  return {
    ...logo,
    light: logo.name === 'current' ? logo.light + `?ts=${now}` : logo.light,
    dark: logo.name === 'current' ? logo.dark + `?ts=${now}` : logo.dark
  }
}

// When the reseller logos data is received, store it and set the selection to 'current'
sample({
  clock: listResellerLogosFx.done,
  fn: response => {
    const { result } = response;
    return {
      data: result.map(addTimestampToCurrentLogo),
      selectionModel: [result.find(l => l.name ==='current')?.id]
    };
  },
  target: spread({ data: $resellerLogos, selectionModel: $resellerSelectionModel })
})

// When the customer logos data is received, store it and set the selection to 'current'
sample({
  clock: listCustomerLogosFx.done,
  fn: response => {
    const { result } = response;
    return {
      data: result.map(addTimestampToCurrentLogo),
      selectionModel: [result.find(l => l.name ==='current')?.id]
    };
  },
  target: spread({ data: $customerLogos, selectionModel: $customerSelectionModel })
})

// When a customer is selected, load the logos for that customer
sample({
  source: $$personaService.$activePersona,
  clock: customerSelected,
  fn: (persona, customer) => (customer ? { customer, params: { resellerId: persona.ghsClientId, customerId: customer.id } } : { customer, logos: VOID }),
  target: spread({ customer: $selectedCustomer, params: listCustomerLogosFx, logos: $customerLogos.reinit })
});

// When a reseller logo is selected, update the selection model
sample({
  clock: resellerLogoSelected,
  target: $resellerSelectionModel
})

// Disable the activate & delete button if current is selected
sample({
  source: $selectedResellerLogo,
  clock: $resellerSelectionModel.updates,
  fn: (selectedResellerLogo) => {
    const disabled = !selectedResellerLogo || selectedResellerLogo?.name === "current";
    return { delete: disabled, activate: disabled };
  },
  target: spread({ delete: $resellerDeleteDisabled, activate: $resellerActivateDisabled})
})

// When a customer logo is selected, update the selection model
sample({
  clock: customerLogoSelected,
  target: $customerSelectionModel
})

sample({
  clock: resellerTableUploadClicked,
  fn: () => true,
  target: $resellerUploadDialogOpen
})

sample({
  clock: resellerUploadDialogClosed,
  fn: () => false,
  target: $resellerUploadDialogOpen
})

sample({
  source: $$personaService.$activePersona,
  clock: resellerUploadDialogUploadClicked,
  fn: ( persona, { name, lightFile, darkFile}) => ({ name, lightFile, darkFile, resellerId: persona.ghsClientId }),
  target: uploadResellerLogoFx
})

sample({
  clock: uploadResellerLogoFx.done,
  target: resellerUploadDialogClosed
})

// When finished uploading, activating, or deleting a new reseller logo, refresh the reseller logos
sample({
  source: $$personaService.$activePersona,
  clock: [uploadResellerLogoFx.done, deleteResellerLogoFx.done, setResellerLogoFx.done],
  fn: (persona) => ({ resellerId: persona.ghsClientId }),
  target: listResellerLogosFx
})

sample({
  source: { persona: $$personaService.$activePersona, logo: $selectedResellerLogo },
  clock: resellerDeleteClicked,
  fn: ({ persona, logo }) => {
    return {
      title: 'Confirm Logo Delete',
      content: `Are you sure you want to delete the ${logo.name} logo?`,
      onAccept: () => {
        deleteResellerLogoFx({ themeName: logo.name, resellerId: persona.ghsClientId })
      },
      acceptButtonText: 'Delete',
      acceptButtonColor: buttonColors.ERROR
    }
  },
  target: $$confirmationDialog.open
})

sample({
  source: { persona: $$personaService.$activePersona, logo: $selectedResellerLogo },
  clock: resellerActivateClicked,
  fn: ({ persona, logo }) => {
    return {
      title: 'Confirm Logo Activation',
      content: `Are you sure you want to activate the ${logo.name} logo?`,
      onAccept: () => {
        setResellerLogoFx({ newThemeName: logo.name, resellerId: persona.ghsClientId });
      },
      acceptButtonText: 'Activate',
    }
  },
  target: $$confirmationDialog.open
})

sample({
  clock: customerTableUploadClicked,
  fn: () => true,
  target: $customerUploadDialogOpen
})

sample({
  clock: customerUploadDialogClosed,
  fn: () => false,
  target: $customerUploadDialogOpen
})

sample({
  source: combine({ persona: $$personaService.$activePersona, customer: $selectedCustomer }),
  clock: customerUploadDialogUploadClicked,
  fn: ( { persona, customer }, { name, lightFile, darkFile}) => ({ name, lightFile, darkFile, resellerId: persona.ghsClientId, customerId: customer.id }),
  target: uploadCustomerLogoFx
})

sample({
  source: $selectedCustomer,
  clock: [ uploadCustomerLogoFx.done, deleteCustomerLogoFx.done, setCustomerLogoFx.done ],
  fn: (customer) => ({ closeDialog: {}, customer}),
  target: spread({ closeDialog: customerUploadDialogClosed, customer: customerSelected })
})

// Disable the activate & delete button if current is selected
sample({
  source: $selectedCustomerLogo,
  clock: $customerSelectionModel.updates,
  fn: (selectedCustomerLogo) => {
    const disabled = !selectedCustomerLogo || selectedCustomerLogo?.name === "current";
    return { delete: disabled, activate: disabled };
  },
  target: spread({ delete: $customerDeleteDisabled, activate: $customerActivateDisabled})
})

sample({
  source: combine({ persona: $$personaService.$activePersona, customer: $selectedCustomer, logo: $selectedCustomerLogo }),
  clock: customerDeleteClicked,
  fn: ({ persona, customer, logo }) => {
    return {
      title: 'Confirm Logo Delete',
      content: `Are you sure you want to delete the ${logo.name} logo?`,
      onAccept: () => {
        deleteCustomerLogoFx({ themeName: logo.name, resellerId: persona.ghsClientId, customerId: customer.id })
      },
      acceptButtonText: 'Delete',
      acceptButtonColor: buttonColors.ERROR
    }
  },
  target: $$confirmationDialog.open
})

sample({
  source: combine({ persona: $$personaService.$activePersona, customer: $selectedCustomer, logo: $selectedCustomerLogo }),
  clock: customerActivateClicked,
  fn: ({ persona, customer, logo }) => {
    return {
      title: 'Confirm Logo Activation',
      content: `Are you sure you want to activate the ${logo.name} logo?`,
      onAccept: () => {
        setCustomerLogoFx({ newThemeName: logo.name, resellerId: persona.ghsClientId, customerId: customer.id });
      },
      acceptButtonText: 'Activate',
    }
  },
  target: $$confirmationDialog.open
})

sample({
  clock: [setResellerLogoFx.done, setCustomerLogoFx.done],
  target: $$logo.logoUpdated,
});

sample({
  clock: [
    listResellerLogosFx.fail.map(response => ({ response, action: 'listing the logos' })),
    deleteResellerLogoFx.fail.map(response => ({ response, action: 'deleting the logo' })),
    uploadResellerLogoFx.fail.map(response => ({ response, action: 'uploading the logo' })),
    setResellerLogoFx.fail.map(response => ({ response, action: 'setting the logo' })),
    listCustomerLogosFx.fail.map(response => ({ response, action: 'listing the logos' })),
    deleteCustomerLogoFx.fail.map(response => ({ response, action: 'deleting the logo' })),
    uploadCustomerLogoFx.fail.map(response => ({ response, action: 'uploading the logo' })),
    setCustomerLogoFx.fail.map(response => ({ response, action: 'setting the logo' }))
  ],
  fn: ({ response, action }) => {
    const { error } = response;
    let message = `An error occurred ${action}.`;
    if (error.response?.data?.message) {
      message = error.response.data.message;
    }
    return { message: message, severity: 'error' };
  },
  target: $$snackbar.open
});

sample({
  clock: $$bannerService.getResellerBannerOptions.doneData,
  fn: options => options.optIn,
  target: $bannerOptIn
})

sample({
  source: $$personaService.$activePersona,
  clock: bannerOptInSwitchClicked,
  fn: (persona, event) => {
    const newValue = event.target.checked;
    const optInMsg = "I understand that GHS will post banner messages for a variety of things including service outages, delays, etc.  These banners can be shown for my customers.";
    const optOutMsg = "I understand by opting out of banner messages that my customers may not be aware of issues that could be affecting their reporting, data feeds, and/or campaign delivery.";
    return {
      title: newValue ? 'Confirm Banner Opt In' : "Confirm Banner Opt Out",
      content: newValue ? optInMsg : optOutMsg,
      onAccept: async () => {
        await $$bannerService.updateResellerBannerOptions({ resellerId: persona.ghsClientId, optIn: newValue });
        await $$bannerService.getResellerBannerOptions({ resellerId: persona.ghsClientId });
      },
      acceptButtonText: 'OK',
    }
  },
  target: $$confirmationDialog.open
})

sample({
  clock: resellerHelpClicked,
  fn: () => {
   return {
     title: 'Reseller Logo Help',
     content: <AdminLogosHelpDialogContent type="reseller"/>
   };
  },
  target: $$infoDialog.open
})

sample({
  clock: customerHelpClicked,
  fn: () => {
    return {
      title: 'Customer Logo Help',
      content: <AdminLogosHelpDialogContent type="customer"/>
    };
  },
  target: $$infoDialog.open
})

export const $$resellerLogosPage = {
  // Stores
  $logoCriteria,
  $isMaxResellerLogosReached,
  $isMaxCustomerLogosReached,
  $isResellerUser,
  $customers,
  $selectedCustomer,
  $resellerLogos,
  $resellerLogosLoading: listResellerLogosFx.pending,
  $resellerSelectionModel,
  $resellerUploadDialogOpen,
  $resellerDeleteDisabled,
  $resellerActivateDisabled,
  $customerLogos,
  $customerSelectionModel,
  $customerUploadDialogOpen,
  $customerLogosLoading: listCustomerLogosFx.pending,
  $customerDeleteDisabled,
  $customerActivateDisabled,
  $bannerOptIn,

  // Events
  tableMounted,
  customerSelected,
  resellerLogoSelected,
  resellerTableUploadClicked,
  resellerUploadDialogClosed,
  resellerUploadDialogUploadClicked,
  resellerDeleteClicked,
  resellerActivateClicked,
  resellerHelpClicked,
  customerLogoSelected,
  customerTableUploadClicked,
  customerUploadDialogClosed,
  customerUploadDialogUploadClicked,
  customerDeleteClicked,
  customerActivateClicked,
  customerHelpClicked,
  bannerOptInSwitchClicked,
};