// @ts-ignore
import { textFieldVariants } from '@ghs/components';
import { useUnit } from 'effector-react';
import { DelayedTextField } from '../../../components/DelayedTextField';
import { $$resellerUserDialog } from '../../../components/ResellerUserDialog/model';
import { $$reseller } from '../../../services/ResellerService/model';
import DataAccessUsersTable from './DataAccessUsersTable';
import { $$adminDataAccessPage } from './model';
import { createUsersTableColumns } from './utils';

/**
 * LinesOfBusinessUsersTable
 *
 * @returns {React.ReactNode} LinesOfBusinessUsersTable
 */
export default function LinesOfBusinessUsersTable() {
  const dataGridProps = $$adminDataAccessPage.$$lobsUsers.useDataGrid();
  const selectedLineOfBusiness = useUnit($$adminDataAccessPage.$selectedLob);
  const userFilter = useUnit($$adminDataAccessPage.$lobUserFilter);

  if (!selectedLineOfBusiness) {
    return null;
  }

  return (
    <>
      <DelayedTextField
        id="admin-data-access-lob-users-autocomplete"
        type="search"
        label="Search User Name or Email"
        placeholder="Search"
        InputLabelProps={{ shrink: true }}
        value={userFilter}
        onChange={value => $$adminDataAccessPage.lobUserFiltered(value)}
        variant={textFieldVariants.OUTLINED}
        sx={{ mb: 2, width: '100%' }}
      />
      <DataAccessUsersTable
        data-testid="admin-data-access-lob-users-datagrid"
        dataGridProps={dataGridProps}
        columns={createUsersTableColumns({
          dataGridProps,
          nameColumnHeaderTitle: 'Line of Business Users',
          addUserButtonTooltip: 'Users added at this level will only have access to one Line of Business',
          onDownloadClick: () => $$adminDataAccessPage.downloadLobsUsersFx(),
          onAddUserClick: () =>
            $$resellerUserDialog.open({
              title: `Add User for ${selectedLineOfBusiness?.name}`,
              onSubmit: data => $$reseller.addLobUserFx({ lineOfBusinessId: selectedLineOfBusiness?.id, data: { ...data, customerId: selectedLineOfBusiness?.customerId } })
            }),
          onEditSubmitted: data => $$reseller.editLobUserFx({ lineOfBusinessId: selectedLineOfBusiness?.id, data }),
          onDeleteClicked: id => $$adminDataAccessPage.lobsUserDeleteClicked(Number(id))
        })}
      />
    </>
  );
}
