// @ts-ignore
import { textFieldVariants } from '@ghs/components';
import { useUnit } from 'effector-react';
import { DelayedTextField } from '../../../components/DelayedTextField';
import { $$resellerUserDialog } from '../../../components/ResellerUserDialog/model';
import { $$reseller } from '../../../services/ResellerService/model';
import DataAccessUsersTable from './DataAccessUsersTable';
import { $$adminDataAccessPage } from './model';
import { createUsersTableColumns } from './utils';

/**
 * CustomersUsersTable
 *
 * @returns {React.ReactNode} CustomersUsersTable
 */
export default function CustomersUsersTable() {
  const dataGridProps = $$adminDataAccessPage.$$customersUsers.useDataGrid();
  const selectedCustomer = useUnit($$adminDataAccessPage.$selectedCustomer);
  const userFilter = useUnit($$adminDataAccessPage.$customerUserFilter);

  if (!selectedCustomer) {
    return null;
  }

  return (
    <>
      <DelayedTextField
        id="admin-data-access-customer-users-autocomplete"
        type="search"
        label="Search User Name or Email"
        placeholder="Search"
        InputLabelProps={{ shrink: true }}
        value={userFilter}
        onChange={value => $$adminDataAccessPage.customerUserFiltered(value)}
        variant={textFieldVariants.OUTLINED}
        sx={{ mb: 2, width: '100%' }}
      />
      <DataAccessUsersTable
        data-testid="admin-data-access-customer-users-datagrid"
        dataGridProps={dataGridProps}
        columns={createUsersTableColumns({
          dataGridProps,
          nameColumnHeaderTitle: 'Customer Users',
          addUserButtonTooltip: 'Users added at this level will have access to all Lines of Business within a single Customer',
          onDownloadClick: () => $$adminDataAccessPage.downloadCustomersUsersFx(),
          onAddUserClick: () =>
            $$resellerUserDialog.open({ title: `Add User for ${selectedCustomer?.name}`, onSubmit: data => $$reseller.addCustomersUserFx({ customerId: selectedCustomer?.id, data }) }),
          // @ts-ignore
          onEditSubmitted: data => $$reseller.editCustomersUserFx({ customerId: selectedCustomer?.id, data }),
          // @ts-ignore
          onDeleteClicked: id => $$adminDataAccessPage.customersUserDeleteClicked(Number(id))
        })}
      />
    </>
  );
}
