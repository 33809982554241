import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import BgTitleStyle from "../BgTitleStyle";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "@ghs/components";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import PropTypes from "prop-types";

/**
 * Dialog to confirm deleting a logo.
 *
 * @param {object} props the props for the component
 * @param {boolean} props.open whether the dialog is open
 * @param {string} props.logoName the name of the logo being deleted
 * @param {Function} props.onClose handler to run when closing the dialog
 * @param {Function} props.onDelete handler to run when delete is confirmed
 * @returns {Element} delete dialog
 */
function DeleteLogoDialog({ open, logoName, onClose, onDelete }) {
  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>Delete Logos Confirmation</DialogTitle>
      <DialogContent>
        <Typography variant="body1" display="inline">
          <b>Are you sure you wish to delete the</b>
        </Typography>
        <Typography color="secondary" display="inline">
          {' '}
          <BgTitleStyle>{logoName}</BgTitleStyle>
        </Typography>
        <Typography variant="body1" display="inline">
          {' '}
          <b>logos?</b>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose} size="medium" variant="contained" sx={{ mt: 1, ml: 1 }}>
          Cancel
        </Button>
        <Button color="error" onClick={onDelete} size="medium" variant="contained" sx={{ mt: 1 }}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteLogoDialog.propTypes = {
  open: PropTypes.bool,
  logoName: PropTypes.string,
  onClose: PropTypes.func,
  onDelete: PropTypes.func
}

export default DeleteLogoDialog;