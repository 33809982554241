// @ts-ignore
import { Dialog, DialogContentText, dialogMaxWidths, ThemeProvider } from '@ghs/components';
import { Button } from '@mui/material';
import { useUnit } from 'effector-react';
import { $$theme } from '../../services/ThemeService/model';
import { $$infoDialog } from './model';

/**
 * InfoDialog
 *
 * @returns {React.ReactNode} - JSX Element
 */
export default function InfoDialog() {
  const open = useUnit($$infoDialog.$isOpen);
  const state = useUnit($$infoDialog.$state);
  const { theme, palettes } = useUnit({ theme: $$theme.$theme, palettes: $$theme.$themePalettes });

  if (!state) {
    return null;
  }

  const { title, content, closeButtonText = 'Close' } = state;

  const palette = palettes[state.theme || theme];

  return (
    <ThemeProvider theme={palette}>
      <Dialog
        title={title}
        open={open}
        fullWidth={true}
        maxWidth={dialogMaxWidths.XS}
        onClose={$$infoDialog.close}
        actions={<Button onClick={() => $$infoDialog.close()}>{closeButtonText}</Button>}
        data-testid="info-dialog"
      >
        <DialogContentText data-testid="info-dialog-text">{content}</DialogContentText>
      </Dialog>
    </ThemeProvider>
  );
}
