import { Autocomplete, Tooltip, textFieldVariants } from '@ghs/components';
import { DeleteForever, Edit } from '@mui/icons-material';
import { Box, Button, Container, IconButton, Switch, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useUnit } from 'effector-react';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import SnackbarRoot from '../../../components/SnackbarRoot/SnackbarRoot';
import { $$tutorialVideos } from '../../../components/TutorialVideosModal/model';
import TutorialVideoButton from '../../../components/TutorialVideosModal/TutorialVideoButton';
import useMountedEvent from '../../../hooks/useMountedEvent';
import { DataGridStyles } from '../../../util/DataGridStyles';
import { $$inHomeWindowsTable } from './model';

/**
 * Component for displaying the form to manage default in home windows.
 *
 * @returns {React.ReactNode} AdminInHomeWindows component
 */
function AdminInHomeWindows() {
  useMountedEvent($$inHomeWindowsTable.tableMounted);

  const columns = [
    { field: 'customer', valueGetter: c => c.name, headerName: 'Customer', flex: 1 },
    { field: 'lineOfBusiness', valueGetter: lob => lob?.name, headerName: 'Line of Business', flex: 1 },
    { field: 'inHomeStart', headerName: 'In-Home Start', flex: 0.5 },
    { field: 'inHomeEnd', headerName: 'In-Home End', flex: 0.5 },
    { field: 'mailClass', valueGetter: mc => mc.description, headerName: 'Mail Class', flex: 0.5 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      align: 'center',
      flex: 0,
      getActions: ({ id, row }) => {
        return [
          <IconButton key={`in-home-edit-button-${id}`} onClick={() => $$inHomeWindowsTable.inRowEditClicked(row)}>
            <Edit fontSize="small" color="primary" />
          </IconButton>,
          <Tooltip key={`in-home-delete-button-${id}`} title="" arrow>
            <IconButton onClick={() => $$inHomeWindowsTable.inRowDeleteClicked(row)}>
              <DeleteForever fontSize="small" color="warning" />
            </IconButton>
          </Tooltip>
        ];
      }
    }
  ];

  const customers = useUnit($$inHomeWindowsTable.$customers);
  const selectedCustomer = useUnit($$inHomeWindowsTable.$selectedCustomer);
  const lobs = useUnit($$inHomeWindowsTable.$lobs);
  const selectedLob = useUnit($$inHomeWindowsTable.$selectedLob);
  const mailClasses = useUnit($$inHomeWindowsTable.$mailClasses);
  const selectedMailClass = useUnit($$inHomeWindowsTable.$selectedMailClass);
  const inHomeStart = useUnit($$inHomeWindowsTable.$inHomeStart);
  const inHomeEnd = useUnit($$inHomeWindowsTable.$inHomeEnd);
  const inHomeStartError = useUnit($$inHomeWindowsTable.$inHomeStartError);
  const inHomeEndError = useUnit($$inHomeWindowsTable.$inHomeEndError);
  const recalculate = useUnit($$inHomeWindowsTable.$recalculate);
  const isFormValid = useUnit($$inHomeWindowsTable.$isFormValid);
  const isDeleteEnabled = useUnit($$inHomeWindowsTable.$isDeleteEnabled);
  const defaultInHomeWindows = useUnit($$inHomeWindowsTable.$defaultInHomeWindows);
  const rows = useUnit($$inHomeWindowsTable.$filteredInHomeWindows);
  const loading = useUnit($$inHomeWindowsTable.$isTableLoading);

  return (
    <>
      <ConfirmationDialog />
      <TutorialVideoButton url={$$tutorialVideos.links['ST_ADMIN:IN_HOME_WINDOWS']} />

      <Container id="external-admin-in-home-windows-container">
        <Typography variant="h4" color="text.primary" sx={{ mb: 6 }}>
          In-Home Windows
        </Typography>
        <Typography variant="h6" color="primary">
          GHS Default In-Home Windows
        </Typography>
        <Box sx={{ mb: 4 }}>
          {defaultInHomeWindows.map(w => (
            <Typography key={w.mailClass.id} color="primary">
              {w.mailClass.description} {w.inHomeStart}-{w.inHomeEnd} days
            </Typography>
          ))}
        </Box>
        <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
          <Grid xs={12} md={6} lg={3}>
            <Stack>
              <Autocomplete
                id="customer-select"
                label="Select Customer"
                placeholder="Select"
                options={customers}
                variant={textFieldVariants.OUTLINED}
                name="customer"
                value={selectedCustomer}
                onChange={(_event, value) => $$inHomeWindowsTable.customerSelected(value)}
                getOptionKey={option => option.id}
              />
              <Autocomplete
                id="lob-select"
                label="Select Line of Business"
                placeholder="Select"
                options={lobs}
                variant={textFieldVariants.OUTLINED}
                sx={{ mt: 2 }}
                name="lob"
                value={selectedLob}
                onChange={(_event, value) => $$inHomeWindowsTable.lobSelected(value)}
                disabled={!selectedCustomer}
              />
            </Stack>
          </Grid>
          <Grid xs={12} md={6} lg={3}>
            <Stack>
              <Autocomplete
                id="mail-class-select"
                label="Mail Class"
                placeholder="Select"
                options={mailClasses}
                variant={textFieldVariants.OUTLINED}
                name="mail-class"
                value={selectedMailClass}
                onChange={(_event, value) => $$inHomeWindowsTable.mailClassSelected(value)}
              />
            </Stack>
          </Grid>
          <Grid xs={12} md={6} lg={3}>
            <Stack>
              <Tooltip title="In-Home Start is the number of calendar days from the 1st Maildate" arrow placement="top">
                <TextField
                  id="in-home-start-input"
                  label="No. of days for in-home START"
                  InputLabelProps={{ shrink: true }}
                  placeholder=""
                  variant={textFieldVariants.OUTLINED}
                  name="in-home-start"
                  value={inHomeStart}
                  onChange={event => $$inHomeWindowsTable.inHomeStartChanged(event.target.value)}
                  disabled={!(selectedCustomer && selectedMailClass)}
                  error={inHomeStartError}
                  helperText={inHomeStartError}
                />
              </Tooltip>
              <Tooltip title="In-Home End is the number of calendar days from the Last Maildate" arrow placement="top">
                <TextField
                  id="in-home-end-input"
                  label="No. of days for in-home END"
                  InputLabelProps={{ shrink: true }}
                  placeholder=""
                  variant={textFieldVariants.OUTLINED}
                  name="in-home-end"
                  value={inHomeEnd}
                  onChange={event => $$inHomeWindowsTable.inHomeEndChanged(event.target.value)}
                  sx={{ mt: 2 }}
                  disabled={!(selectedCustomer && selectedMailClass)}
                  error={inHomeEndError}
                  helperText={inHomeEndError}
                />
              </Tooltip>
            </Stack>
          </Grid>
          <Grid xs={12} md={6} lg={3}>
            <Stack>
              <Tooltip
                title="When toggled on, in-home dates for active campaigns/versions will be recalculated to use the new windows. If toggled off, the new in-home windows will only be applied to new campaigns/versions."
                arrow
                placement="top"
              >
                <Stack direction="row" sx={{ p: 0.5 }}>
                  <Switch defaultChecked value={recalculate} onChange={event => $$inHomeWindowsTable.recalculateChanged(event.target.checked)} color="success" />
                  <Typography color="primary">
                    RECALCULATE FOR
                    <br />
                    CAMPAIGNS&nbsp;&&nbsp;VERSIONS
                  </Typography>
                </Stack>
              </Tooltip>
              <Stack direction="row" sx={{ mt: 2, p: 0.5 }}>
                <Button variant="outlined" sx={{ ml: 2 }} disabled={!isDeleteEnabled} onClick={$$inHomeWindowsTable.deleteClicked}>
                  Delete
                </Button>
                <Button variant="contained" sx={{ ml: 2 }} disabled={!isFormValid} onClick={$$inHomeWindowsTable.saveClicked}>
                  Save
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <DataGridPro
          autoHeight
          columns={columns}
          rows={rows}
          getRowId={row => `${row.customer.id}-${row.lineOfBusiness?.id}-${row.mailClass.id}`}
          disableRowSelectionOnClick
          onRowDoubleClick={({ row }) => $$inHomeWindowsTable.inRowEditClicked(row)}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          loading={loading}
          localeText={{
            noRowsLabel: selectedCustomer ? 'No results' : 'Select a Customer'
          }}
          pageSizeOptions={[10, 25, 50]}
          pagination
          sx={{
            mt: 2,
            '& .MuiDataGrid-cell:focus': { outline: 'none' },
            '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
            ...DataGridStyles
          }}
        />
        <SnackbarRoot snackbarProps={{ autoHideDuration: 3000 }} />
      </Container>
    </>
  );
}

AdminInHomeWindows.propTypes = {};

export default AdminInHomeWindows;
