import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Alert, Link } from "@ghs/components";
import BiDashboardEmbed from "../components/BiDashboardEmbed";
import { $$bi, getSisenseInfo } from "../services/BiService";
import PropTypes from "prop-types";
import useAuth0WithErrorHandling from "../hooks/useAuth0WithErrorHandling";
import { useLocation, Link as RouterLink } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { hasDashboardPermission } from "../services/UserService/UserService";
import { useUnit } from "effector-react";
import {UserContext} from "../UserContext";
import {$$contactService} from "../services/ContactService/model";

/**
 * @typedef {import("../services/BiService").SisenseInfo} SisenseInfo
 */

const BiDashboard = ({ dashboardId: propDashboardId, unmountShouldDestroyFrame, unmountShouldUnloadEmbedSdk,
                         containerElement: sisenseContainerElement, containerElementId: sisenseContainerElementId }) => {

    const userDetails = React.useContext(UserContext);
    const biThemeId = useUnit($$bi.$biThemeId);
    const location = useLocation();
    const queryParameters = new URLSearchParams(window.location.search)
    const [statusMessage, setStatusMessage] = React.useState("");
    const [sisenseInfo, setSisenseInfo] = React.useState(/** @type { SisenseInfo | null } */ (null));
    // Use the propDashboardId if specified (In home page), otherwise fall back to query parameter (In dashboards page)
    const dashboardId = propDashboardId || queryParameters.get("dashboardId");
    const containerElement = sisenseContainerElement || React.createRef();
    const containerElementId = sisenseContainerElementId || "dashboard-container";
    const { getAccessTokenSilently } = useAuth0WithErrorHandling();
    const contactInfo = useUnit($$contactService.$contactInfo);

    React.useEffect(() => {
        /**
         * Initializes the component
         *
         * @returns {Promise<void>} Promise with the result of initialization
         */
        async function init() {
            try {
                if (hasDashboardPermission(userDetails.permissions)) {
                    setStatusMessage('Authenticating against BI platform...');
                    const accessToken = await getAccessTokenSilently();
                    const fetchedSisenseInfo = await getSisenseInfo(accessToken);
                    setSisenseInfo(fetchedSisenseInfo);

                    // Update the browser tab title with the name of the dashboard. We could avoid this by adding the
                    // dashboard title as a query parameter, but would make the URL to look ugly. The dashboard info is
                    // loaded into session so this doesn't require additional calls to Sisense server.
                    if (location.pathname !== "/") {
                        const dashboard = fetchedSisenseInfo.dashboards.find(item => item.id === dashboardId);
                        if (dashboard?.title) {
                            document.title = dashboard.title;
                        }
                    }

                    setStatusMessage("");
                }
            } catch (e) {
                setStatusMessage(`Error authenticating against BI platform`);
            }
        }
        init();
    }, [biThemeId]);

    const shouldDisplayDashboard = () => {
        return hasDashboardPermission(userDetails.permissions) && sisenseInfo?.authenticated && sisenseInfo?.dashboards.some((dashboard) => dashboard.id === dashboardId);
    };

    if (!hasDashboardPermission(userDetails.permissions)) {
        return null;
    }

    if (!sisenseInfo) {
        return <CircularProgress/>
    }

    return shouldDisplayDashboard() ?
        (<Box>
            <Typography color="text.primary">{statusMessage}</Typography>
            <BiDashboardEmbed
                serverUrl={process.env.REACT_APP_BI_SERVER_URL}
                dashboardId={dashboardId}
                showLeftPane={process.env.REACT_APP_BI_SERVER_SHOW_LEFT_PANE === "true"}
                showRightPane={process.env.REACT_APP_BI_SERVER_SHOW_RIGHT_PANE === "true"}
                showToolbar={process.env.REACT_APP_BI_SERVER_SHOW_TOOLBAR === "true"}
                themeId={biThemeId}
                unmountShouldDestroySisenseFrame={unmountShouldDestroyFrame}
                unmountShouldUnloadEmbedSdk={unmountShouldUnloadEmbedSdk}
                sisenseContainerElement={containerElement}
                sisenseContainerElementId={containerElementId}
                timeout={process.env.REACT_APP_SESSION_TIMEOUT * 1000}
                promptBeforeIdle={process.env.REACT_APP_SESSION_TIMEOUT_WARNING * 1000}
                crossTab={true}
            />
        </Box>) :
        (<Box display="flex" justifyContent="center" alignItems="center">
            <Box sx={{ maxWidth: 'lg' }}>
                <Alert id="curating-msg" severity="info" sx={{ mb: 2, ml: 3, mr: 3}}>
                    <Fragment>
                        We know you are eager to see your data, but please be patient. Our data curation process is like a
                        fine wine, it takes time to mature and improve. We are not just dumping some random numbers on your
                        screen, we are crafting a masterpiece of information that is custom-made for you.
                        <br /><br />
                        You may wonder why we need to curate your data so carefully. Well, it’s because we care about your
                        privacy and security. We don’t want anyone else to peek at your data or tamper with it. That’s why we
                        use the latest encryption and auditing techniques to make sure your data is safe and sound.
                        <br /><br />
                        While you are waiting for your data to be ready, you can still use our <Link component={RouterLink}
                                                                                                     to={"/lookup"}>single
                        piece lookup</Link>. This feature allows you to search for any individual piece of data that you need,
                        without having to wait for the whole dataset. It’s like a snack before the main course. Just type in
                        what you are looking for and we will fetch it for you in seconds.
                        <br /><br />
                        If something goes wrong and you still see this message after 2 hours of waiting, please
                        { contactInfo?.showContactForm ?
                            <> let us know by using the <Link component={RouterLink} to={"/contact"}>contact form</Link>. We will try to fix the problem as soon as possible. </> :
                            <> contact your tracking supplier. </>
                        }
                        We apologize for any inconvenience caused by our meticulous data curation process.
                    </Fragment>
                </Alert>
            </Box>
        </Box>);
};

BiDashboard.propTypes = {
    dashboardId: PropTypes.string,
    // Sisense properties
    unmountShouldDestroyFrame: PropTypes.bool,
    unmountShouldUnloadEmbedSdk: PropTypes.bool,
    containerElement: PropTypes.object,
    containerElementId: PropTypes.string
};

export default BiDashboard;