import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Table as MuiTable,
  Box as MuiBox,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableBody as MuiTableBody,
  TablePagination as MuiTablePagination,
  useTheme
} from '@mui/material';

import Checkbox from '~/components/Checkbox';

export const tableSizes = {
  MEDIUM: 'medium',
  SMALL: 'small'
};

const Table = ({
  rows,
  columns,
  rowsPerPage,
  size,
  checkboxSelection,
  noColumns,
  selectedRows,
  hovered,
  ...overrides
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentRowsPerPage, setRowsPerPage] = useState(
    rowsPerPage || Math.ceil(rows.length / 2)
  );

  const [currentSelectedRows, setSelectedRows] = useState(selectedRows);
  const theme = useTheme();

  // Attaching an index to each row, for identification later.
  const tableRows = rows.map((row, idx) => ({
    ...row,
    index: idx
  }));

  // Fields that will be mapped on each table row.
  const rowFields = columns.map(col => col.field);

  const currentPageData = tableRows.slice(
    currentPage * currentRowsPerPage,
    currentPage * currentRowsPerPage + currentRowsPerPage
  );

  const isAllRowsSelected =
    currentSelectedRows.length !== 0 &&
    currentSelectedRows.length === tableRows.length;

  const handleAllRowsSelection = () => {
    if (!isAllRowsSelected) {
      const newSelectedRows = tableRows.map(row => row.index);

      setSelectedRows(newSelectedRows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelection = idx => {
    const isAlreadySelected = currentSelectedRows.includes(idx);

    if (isAlreadySelected) {
      // Removing current row from selection.
      setSelectedRows(currentSelectedRows.filter(rowIdx => rowIdx !== idx));
    } else {
      setSelectedRows([...currentSelectedRows, idx]);
    }
  };

  return (
    <>
      <MuiTableContainer component={MuiBox} sx={{ width: '100%' }}>
        <MuiTable size={size} aria-label="simple table" {...overrides}>
          {!noColumns && (
            <MuiTableHead>
              <MuiTableRow role="checkbox" selected={isAllRowsSelected}>
                {checkboxSelection && (
                  <MuiTableCell scope="col" padding="checkbox" sx={{ px: 2 }}>
                    <Checkbox
                      color="primary"
                      aria-label="select all"
                      onChange={handleAllRowsSelection}
                      checked={isAllRowsSelected}
                    />
                  </MuiTableCell>
                )}
                {columns.map(column => (
                  <MuiTableCell
                    key={column.field}
                    aria-label={column.headerName}
                    component="th"
                  >
                    {column.headerName}
                  </MuiTableCell>
                ))}
              </MuiTableRow>
            </MuiTableHead>
          )}
          <MuiTableBody>
            {currentPageData.map((row, i) => {
              const isSelected = currentSelectedRows.includes(row.index);

              return (
                <MuiTableRow
                  key={i}
                  role="checkbox"
                  selected={isSelected}
                  hover
                  sx={{
                    backgroundColor: hovered ? theme.palette.action.hover : ''
                  }}
                  data-cell="row"
                >
                  {checkboxSelection && (
                    <MuiTableCell padding="checkbox" sx={{ px: 2 }}>
                      <Checkbox
                        color="primary"
                        aria-label="select row"
                        checked={isSelected}
                        onChange={() => handleRowSelection(row.index)}
                      />
                    </MuiTableCell>
                  )}
                  {rowFields.map(field => {
                    const currentCellData = field in row ? row[field] : '-';

                    return (
                      <MuiTableCell key={field} component="td">
                        {currentCellData}
                      </MuiTableCell>
                    );
                  })}
                </MuiTableRow>
              );
            })}
          </MuiTableBody>
        </MuiTable>
      </MuiTableContainer>
      {currentRowsPerPage < tableRows.length && (
        <MuiTablePagination
          rowsPerPageOptions={[
            tableRows.length - 1,
            Math.ceil(tableRows.length / 2)
          ]}
          rowsPerPage={currentRowsPerPage}
          component="div"
          count={tableRows.length}
          page={currentPage}
          onPageChange={(_event, newPage) => setCurrentPage(newPage)}
          onRowsPerPageChange={event => setRowsPerPage(event.target.value)}
        />
      )}
    </>
  );
};

Table.propTypes = {
  // rows can be dynamic according to the given columns.
  rows: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      headerName: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired
    })
  ).isRequired,
  size: PropTypes.oneOf(Object.values(tableSizes)),
  rowsPerPage: PropTypes.number,
  checkboxSelection: PropTypes.bool,
  noColumns: PropTypes.bool,
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  hovered: PropTypes.bool
};

Table.defaultProps = {
  checkboxSelection: false,
  noColumns: false,
  hovered: false,
  selectedRows: []
};

export default Table;
