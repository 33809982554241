import { Button, Chip, Switch } from '@ghs/components';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Container, IconButton } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DataGridPro, GridToolbarContainer } from '@mui/x-data-grid-pro';
import * as React from 'react';
import BannerDialog from '../../components/BannerDialog';
import DeleteConfirmationDialog from '../../components/DeleteConfirmationDialog';
import useAuth0WithErrorHandling from '../../hooks/useAuth0WithErrorHandling';
import { $$bannerService, deleteBanner } from '../../services/BannerService';

/**
 * Component for displaying the form to manage banners.
 *
 * @returns {Element} GhsAdminBanners component
 * @class
 */
function GhsAdminBanners() {
  const [data, setData] = React.useState(/** @type {import('../../services/BannerService').Banner[]} */ ([]));
  const [banner, setBanner] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState('Create');
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const [onlyShowActiveBanners, setOnlyShowActiveBanners] = React.useState(false);
  const [filterModel, setFilterModel] = React.useState({
    items: []
  });
  const { getAccessTokenSilently } = useAuth0WithErrorHandling();

  const onClickBannerDialog = async (_e, currentBanner, dialogMode) => {
    if (dialogMode == 'edit') {
      setDialogTitle('Edit banner');
      setBanner(currentBanner);
    } else {
      setDialogTitle('Create a banner');
    }
    setDialogOpen(true);
  };

  const onClickDelete = async currentBanner => {
    setBanner(currentBanner);
    setDeleteDialogOpen(true);
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
    { field: 'content', headerName: 'Text', flex: 2, minWidth: 200 },
    { field: 'severity', headerName: 'Severity', flex: 1, renderCell: params => severityRenderer(params), minWidth: 150 },
    { field: 'active', headerName: 'Active', type: 'boolean', flex: 1, minWidth: 25 },
    { field: 'pages', headerName: 'Pages', flex: 1, minWidth: 50 },
    {
      field: 'id',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'left',
      flex: 1,
      minWidth: 50,
      renderCell: params => (
        <Container sx={'justify-content: center'}>
          <IconButton
            onClick={event =>
              onClickBannerDialog(
                event,
                data.find(row => row.id == params.value),
                'edit'
              )
            }
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={() => onClickDelete(data.find(row => row.id == params.value))}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Container>
      )
    }
  ];

  const columnWidths = columns.reduce((acc, column) => {
    return acc + column.width;
  }, 0);

  React.useEffect(() => {
    $$bannerService.getAllBannersFx().then(setData);
  }, []);

  /**
   * Built a custom toolbar with add button.
   *
   * @returns {Element} datagrid toolbar
   */
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Container>
          <Button color="primary" size="small" startIcon={<AddIcon fontSize="small" />} onClick={onClickBannerDialog}>
            Create
          </Button>
          <FormControlLabel
            sx={{ minWidth: 100, 'margin-left': 30 }}
            checked={onlyShowActiveBanners}
            onChange={event => {
              setOnlyShowActiveBanners(() => event.target.checked);
              if (event.target.checked) {
                setFilterModel(model => ({
                  ...model,
                  items: [{ id: 1, field: 'active', operator: 'is', value: 'true' }]
                }));
              } else {
                setFilterModel({ items: [] });
              }
            }}
            control={<Switch color="primary" size="small" />}
            label="Only Show Active Banners"
            color="primary"
          />
        </Container>
      </GridToolbarContainer>
    );
  }

  return (
    <Container id="banner-container" sx={{ minWidth: columnWidths + 150 }}>
      <DataGridPro
        columns={columns}
        rows={data}
        getRowId={row => row.id}
        pageSize={15}
        filterModel={filterModel}
        slots={{
          toolbar: CustomToolbar
        }}
        disableRowSelectionOnClick
      />
      {dialogOpen && (
        <BannerDialog
          onClose={(_e, reloadData, newBanner) => {
            setDialogOpen(false);
            if (reloadData) {
              setData([...data.filter(row => row.id != newBanner.id), newBanner]);
            }
            setBanner(null);
          }}
          banner={banner}
          open={dialogOpen}
          title={dialogTitle}
        />
      )}
      {deleteDialogOpen && (
        <DeleteConfirmationDialog
          onClose={async (_e, value) => {
            setDeleteDialogOpen(false);
            if (value) {
              const accessToken = await getAccessTokenSilently();
              deleteBanner(banner, accessToken);
              setData([...data.filter(row => row.id !== banner.id)]);
            }
            setBanner(null);
          }}
          bannerName={banner?.name}
          open={deleteDialogOpen}
        />
      )}
    </Container>
  );
}

GhsAdminBanners.propTypes = {};

/**
 * Renderer for severity column datagrid
 *
 * @param {object} params parameters from datagrid
 * @returns {Element} A react component to show at cell
 */
function severityRenderer(params) {
  switch (params.value) {
    case 'success':
      return <Chip icon={<CheckCircleOutlineIcon />} label="Success" color="success" />;
    case 'info':
      return <Chip icon={<InfoOutlinedIcon />} label="Info" color="info" />;
    case 'error':
      return <Chip icon={<ErrorOutlineIcon />} label="High Severity Alert" color="error" />;
    case 'warning':
      return <Chip icon={<ReportProblemOutlinedIcon />} label="Low Severity Alert" color="warning" />;
  }
}

export default GhsAdminBanners;
