// @ts-ignore
import { buttonColors } from '@ghs/components';
import { EventCallable } from 'effector';
import { createElement } from 'react';
import { $$confirmationDialog } from '../ConfirmationDialog/model';
import ConfirmationWithMemoryContent from '../ConfirmationWithMemoryContent/ConfirmationWithMemoryContent';

type CreateDeleteConfirmationDialogArgParams = {
  user: Users.User;
  deleteConfirmationSkipChecked: EventCallable<boolean>;
  deleteConfirmed: EventCallable<Users.User['userId']>;
};

export const createDeleteConfirmationDialogArg = (params: CreateDeleteConfirmationDialogArgParams): (typeof $$confirmationDialog.open)['__'] => {
  const { user, deleteConfirmationSkipChecked, deleteConfirmed } = params;
  return {
    title: `Remove ${user.name}`,
    content: createElement(ConfirmationWithMemoryContent, {
      title: 'Are you sure you want to remove this user?',
      comment: 'This will remove their associated permissions',
      onValueChange: deleteConfirmationSkipChecked
    }),
    buttons: [
      {
        children: 'Cancel',
        variant: 'outlined',
        onClick: () => {
          $$confirmationDialog.close();
        }
      },
      {
        children: 'Remove user',
        variant: 'contained',
        color: buttonColors.ERROR,
        onClick: () => {
          deleteConfirmed(user.userId);
          $$confirmationDialog.close();
        }
      }
    ]
  };
};
