import { GridEditModes, GridRowModes, useGridApiRef } from '@mui/x-data-grid-pro';
import { attach, createEffect, createEvent, createStore, restore, sample } from 'effector';
import { useUnit } from 'effector-react';
import { isEqual, keyBy, uniq, values } from 'lodash';
import { delay, spread } from 'patronum';
import { useEffect } from 'react';
import { mergeByMultipleKeys, treeShakeObject, VOID } from './JsonUtils';

/**
 * createState
 *
 * @template {Record<string, any>} T
 * @param {T} [initialState] - initialState
 * @param {import('effector').Event<void>} [reset] - reset event
 * @returns {[import('effector').Store<T>, import('effector').EventCallable<T>]} - State
 */
const createState = (initialState, reset) => {
  const $state = createStore(/** @type {T} */ (initialState));
  /** @type {import('effector').EventCallable<T>} */
  const set = createEvent();

  sample({
    source: $state,
    clock: set,
    filter: (state, newState) => !isEqual(state, newState),
    fn: (_, newState) => newState,
    target: $state
  });

  if (reset) {
    sample({
      clock: reset,
      target: $state.reinit
    });
  }

  return [$state.map(d => d), set];
};

/**
 * createPaginationPropsObject - this function was moved out from createDataGridModel to reduce the complexity of the function (SonarQube suggestion)
 *
 * @template {Record<string, any>} T
 * @param {{
 *  pagination: PaginatedResponse<T> | null;
 *  type: 'server' | 'client';
 *  paginationChanged: import('effector').EventCallable<import('@mui/x-data-grid').GridPaginationModel>;
 * }} params - Params
 * @returns {{
 *  paginationModel: {
 *   page: number;
 *   pageSize: number;
 *  };
 *  onPaginationModelChange: import('effector').EventCallable<import('@mui/x-data-grid').GridPaginationModel>;
 *  rowCount: number;
 * }} - PaginationProps
 */
function createPaginationPropsObject(params) {
  const { pagination, type, paginationChanged } = params;

  const paginationProps = {};

  if (type === 'server') {
    paginationProps.paginationModel = {
      page: pagination?.pageable.pageNumber ?? 0,
      pageSize: pagination?.pageable.pageSize ?? 10
    };
    paginationProps.onPaginationModelChange = paginationChanged;
    paginationProps.rowCount = pagination?.totalElements ?? 0;
  }
  return paginationProps;
}

/**
 * createDataGridModel
 *
 * @template {Record<string, any>} T
 * @param {{
 *  refetch?: import('effector').Event<unknown> | import('effector').Event<unknown>[];
 *  rowIdField?: keyof T;
 * } & ({ type: 'server'; effect: import('effector').Effect<object, PaginatedResponse<T>> } | { type: 'client', effect: import('effector').Effect<object, T[]>; })} [params] - Params
 * @returns {DataGridFactory<T>} - DataGridFactory
 */
export default function createDataGridModel(params) {
  const { type = 'client', effect, refetch = createEvent(), rowIdField = 'id' } = params || {};

  const getRowId = row => row[rowIdField];

  const getFreshRowsFx = attach({ effect: effect || createEffect(() => /** @type {T[]} */ ([])) });

  /**
   * @typedef {import('@mui/x-data-grid-pro').GridRowId} GridRowId
   * @typedef {import('@mui/x-data-grid-pro').GridApiPro} GridApiPro
   */

  /** @type {import('effector').EventCallable<{ id: GridRowId; field: keyof T }>} */
  const resetCellValue = createEvent();

  /** @type {import('effector').EventCallable<void>} */
  const resetAllChanges = createEvent();

  /** @type {import('effector').EventCallable<{ id: GridRowId; field: keyof T; value: unknown }>} */
  const cellValueChanged = createEvent();

  /** @type {import('effector').EventCallable<GridApiPro | null>} */
  const apiRefChanged = createEvent();

  /** @type {import('effector').EventCallable<GridRowId[]>} */
  const highlightRows = createEvent();

  /** @type {import('effector').EventCallable<T[]>} */
  const addRows = createEvent();

  /** @type {import('effector').EventCallable<GridRowId[]>} */
  const removeRowsByIds = createEvent();

  /** @type {import('effector').EventCallable<{ id: import('@mui/x-data-grid').GridRowId; mode: import('@mui/x-data-grid').GridRowModes }>} */
  const setRowMode = createEvent();

  /** @type {import('effector').EventCallable<import('@mui/x-data-grid').GridRowModes>} */
  const setAllRowsMode = createEvent();

  /** @type {import('effector').EventCallable<import('@mui/x-data-grid').GridRowId>} */
  const resetRowChanges = createEvent();

  /** @type {import('effector').EventCallable<DataGridError<T>[]>} */
  const setErrors = createEvent();

  /** @type {import('effector').EventCallable<Pick<DataGridError<T>, 'id' | 'field'>>} */
  const resetCellError = createEvent();

  /** @type {import('effector').EventCallable<DataGridError<T> | DataGridError<T>[]>} */
  const addCellError = createEvent();

  /** @type {import('effector').EventCallable<DataGridError<T>[]>} */
  const addCellErrors = createEvent();

  /** @type {import('effector').EventCallable<GridRowId[]>} */
  const resetRowsErrors = createEvent();

  /** @type {import('effector').EventCallable<GridRowId>} */
  const selectRow = createEvent();

  /** @type {import('effector').EventCallable<GridRowId>} */
  const unselectRow = createEvent();

  /** @type {import('effector').EventCallable<void>} */
  const selectAllRows = createEvent();

  /** @type {import('effector').EventCallable<void>} */
  const unselectAllRows = createEvent();

  /** @type {import('effector').EventCallable<void>} */
  const reinitAll = createEvent();

  /** @type {import('effector').EventCallable<import('@mui/x-data-grid').GridSortModel>} */
  const sortModelChange = createEvent();

  /** @type {import('effector').Event<void>} */
  const reinit = sample({ clock: [reinitAll, apiRefChanged.filter({ fn: d => !d })], fn: () => VOID });

  const $data = createStore(/** @type {T[]} */ ([])).reset(reinit);

  const $unsavedChanges = createStore(/** @type {{ id: GridRowId; field: keyof T; value: unknown }[]} */ ([])).reset(reinit);

  const $updatedRows = createStore(/** @type {T[]} */ ([])).reset(reinit);

  const $rows = createStore(/** @type {T[]} */ ([])).reset(reinit);

  const $apiRef = createStore(/** @type {GridApiPro | null} */ (null));

  const $highlightedRows = createStore(/** @type {GridRowId[]} */ ([])).reset(reinit);

  const $receivedPagination = createStore(/** @type {PaginatedResponse<T> | null} */ (null)).reset(reinit);

  const $visibleRowsIds = createStore(/** @type {GridRowId[]} */ ([])).reset(reinit);

  const $errors = createStore(/** @type {DataGridError<T>[]} */ ([])).reset(reinit);

  const $sortModel = createStore(/** @type {import('@mui/x-data-grid').GridSortModel} */ ([]));

  const [$rowModesModel, rowModesModelChanged] = createState(/** @type {import('@mui/x-data-grid-pro').GridRowModesModel} */ ({}), reinit);

  const [$dataGridPagination, paginationChanged] = createState(/** @type {import('@mui/x-data-grid-pro').GridPaginationModel} */ ({ page: 0, pageSize: 10 }), reinit);

  const [$selectedRowsIds, selectionModelChanged] = createState(/** @type {Readonly<import('@mui/x-data-grid-pro').GridRowId[]>} */ ([]), reinit);

  /** @type {import('effector').Effect<{cells: DataGridChangeUnit<T>; apiRef: GridApiPro | null }, void>} */
  const setEditCellValuesFx = createEffect(({ cells, apiRef }) => {
    cells.filter(({ value }) => value !== undefined && value !== null).forEach(apiRef.setEditCellValue);
  });

  /** @type {DataGridFactory<T>['useDataGrid']} */
  const useDataGridApiRef = () => {
    const apiRef = useGridApiRef();

    const pagination = useUnit($receivedPagination);
    const isTableLoading = useUnit(getFreshRowsFx.pending);
    const rows = useUnit($rows);
    const rowModesModel = useUnit($rowModesModel);
    const rowSelectionModel = useUnit($selectedRowsIds);
    const sortModel = useUnit($sortModel);

    useEffect(() => {
      apiRefChanged(apiRef.current);
      return () => {
        apiRefChanged(null);
      };
    }, [apiRef]);

    return {
      apiRef,
      loading: isTableLoading,
      rows,
      paginationMode: type,
      getRowId,
      ...createPaginationPropsObject({ pagination, type, paginationChanged }),
      editMode: GridEditModes.Row,
      rowModesModel,
      rowSelectionModel,
      sortingMode: type,
      sortModel,
      onRowModesModelChange: value => rowModesModelChanged(value),
      onRowSelectionModelChange: value => selectionModelChanged(value),
      onSortModelChange: value => sortModelChange(value)
    };
  };

  sample({
    clock: apiRefChanged,
    target: $apiRef
  });

  sample({
    source: {
      data: $data,
      unsavedChanges: $unsavedChanges,
      updatedRows: $updatedRows
    },
    clock: [$unsavedChanges.updates],
    fn: ({ data, unsavedChanges, updatedRows }) =>
      values(unsavedChanges.reduce((acc, { id, field, value }) => ({ ...acc, [id]: { ...keyBy(data, rowIdField)[id], ...keyBy(updatedRows, rowIdField)[id], ...acc[id], [field]: value } }), {})),
    target: $updatedRows
  });

  sample({
    source: {
      data: $data,
      unsavedChanges: $unsavedChanges,
      visibleRowsIds: $visibleRowsIds
    },
    fn: ({ data, unsavedChanges, visibleRowsIds }) =>
      visibleRowsIds.map(id => keyBy(data, rowIdField)[id]).map(row => ({ ...row, ...unsavedChanges.filter(c => c.id === getRowId(row)).reduce((acc, c) => ({ ...acc, [c.field]: c.value }), {}) })),
    target: $rows
  });

  sample({
    source: {
      data: $data,
      unsavedChanges: $unsavedChanges
    },
    clock: cellValueChanged,
    fn: ({ data, unsavedChanges }, { id, field, value }) =>
      [...unsavedChanges.filter(c => c.id !== id || c.field !== field), { id, field, value }].filter(c => data.find(row => getRowId(row) === c.id)?.[c.field] !== c.value),
    target: $unsavedChanges
  });

  sample({
    source: $unsavedChanges,
    clock: resetCellValue,
    fn: (unsavedChanges, { id, field }) => unsavedChanges.filter(c => c.id !== id || c.field !== field),
    target: $unsavedChanges
  });

  sample({
    source: $highlightedRows,
    clock: highlightRows,
    fn: (highlightedRows, ids) => [...highlightedRows, ...ids],
    target: $highlightedRows
  });

  sample({
    source: $highlightedRows,
    clock: delay({ source: highlightRows, timeout: 4000 }),
    fn: (highlightedRows, ids) => highlightedRows.filter(i => !ids.includes(i)),
    target: $highlightedRows
  });

  if (type && effect) {
    if (type === 'client') {
      sample({
        clock: [apiRefChanged.map(Boolean).filter({ fn: Boolean }), ...(Array.isArray(refetch) ? refetch : [refetch])],
        filter: restore(apiRefChanged, null).map(Boolean),
        fn: () => VOID,
        target: getFreshRowsFx
      });
      sample({
        clock: getFreshRowsFx.doneData,
        fn: data => ({ $data: data, $visibleRowsIds: data.map(getRowId) }),
        target: spread({ $data, $visibleRowsIds })
      });
    }
    if (type === 'server') {
      sample({
        source: {
          pagination: $dataGridPagination.map(({ page, pageSize }) => ({ page, size: pageSize })),
          sorting: $sortModel.map(sorting => sorting[0] || null),
          isTableMounted: restore(apiRefChanged, null).map(Boolean),
          _refetch: restore(sample({ clock: [...(Array.isArray(refetch) ? refetch : [refetch])] }), null).map(() => new Date())
        },
        filter: ({ isTableMounted }) => isTableMounted,
        fn: ({ pagination, sorting }) => treeShakeObject({ ...pagination, ...(sorting ? { sort: `${sorting.field},${sorting.sort || 'asc'}` } : {}) }),
        target: getFreshRowsFx
      });

      sample({
        // @ts-ignore
        source: $data,
        clock: getFreshRowsFx.doneData,
        // @ts-ignore
        fn: (data, { content: receivedData, ...pagination }) => ({
          $data: values({ ...keyBy(data, rowIdField), ...keyBy(receivedData, rowIdField) }),
          $visibleRowsIds: receivedData.map(getRowId),
          $receivedPagination: pagination
        }),
        target: spread({ $data, $visibleRowsIds, $receivedPagination })
      });
    }
  }

  sample({
    source: {
      data: $data,
      visibleRowsIds: $visibleRowsIds
    },
    clock: addRows,
    fn: ({ data, visibleRowsIds }, receivedData) => ({
      $data: values({ ...keyBy(data, rowIdField), ...keyBy(receivedData, rowIdField) }),
      $visibleRowsIds: visibleRowsIds.filter(id => !receivedData.map(getRowId).includes(id)).concat(receivedData.map(getRowId))
    }),
    target: spread({ $data, $visibleRowsIds })
  });

  sample({
    source: {
      data: $data,
      visibleRowsIds: $visibleRowsIds
    },
    clock: removeRowsByIds,
    fn: ({ data, visibleRowsIds }, ids) => ({
      $data: data.filter(row => !ids.includes(getRowId(row))),
      $visibleRowsIds: visibleRowsIds.filter(id => !ids.includes(id))
    }),
    target: spread({ $data, $visibleRowsIds })
  });

  sample({
    source: $rowModesModel,
    clock: setRowMode,
    fn: (rowModesModel, { id, mode }) => ({ ...rowModesModel, [id]: { mode } }),
    target: rowModesModelChanged
  });

  sample({
    source: $rowModesModel,
    clock: setAllRowsMode,
    fn: (rowModesModel, mode) => Object.fromEntries(Object.keys(rowModesModel).map(id => [id, { mode }])),
    target: rowModesModelChanged
  });

  sample({
    source: $unsavedChanges,
    clock: resetRowChanges,
    fn: (unsavedChanges, id) => unsavedChanges.filter(c => c.id !== id),
    target: $unsavedChanges
  });

  sample({
    clock: resetAllChanges,
    fn: () => [],
    target: $unsavedChanges
  });

  sample({
    clock: setErrors,
    target: $errors
  });

  sample({
    source: $errors,
    clock: [resetCellError, cellValueChanged, resetCellValue, resetRowChanges.map(id => ({ id, field: null })), resetAllChanges.map(() => ({ id: null, field: null }))],
    /** @type {(errors: DataGridError<T>[], params: { id: import('@mui/x-data-grid').GridRowId | null; field: keyof T | null }) => DataGridError<T>[]} */
    fn: (errors, { id = null, field = null }) => errors.filter(e => !((id === null || e.id === id) && (field === null || e.field === field))),
    target: $errors
  });

  sample({
    clock: addCellError.map(e => (Array.isArray(e) ? e : [e])),
    target: addCellErrors
  });

  sample({
    source: $errors,
    clock: addCellErrors,
    /** @type {(a1: DataGridError<T>[], a2: DataGridError<T>[]) => DataGridError<T>[]} */
    fn: (oldErrors, newErrors) => mergeByMultipleKeys(oldErrors, newErrors, ['id', 'field']),
    target: $errors
  });

  sample({
    source: $errors,
    clock: resetRowsErrors,
    fn: (errors, rowIds) => errors.filter(e => !rowIds.includes(e.id)),
    target: $errors
  });

  sample({
    source: $errors,
    clock: resetRowChanges,
    fn: (errors, id) => errors.filter(e => e.id !== id),
    target: $errors
  });

  sample({
    clock: cellValueChanged,
    fn: ({ id }) => ({ id, mode: GridRowModes.Edit }),
    target: setRowMode
  });

  sample({
    source: $apiRef,
    clock: $rows.updates.map(rows => rows.map(row => Object.entries(row).map(([field, value]) => ({ id: getRowId(row), field, value }))).flat()),
    fn: (apiRef, cells) => ({ cells, apiRef }),
    target: setEditCellValuesFx
  });

  sample({
    source: $selectedRowsIds,
    clock: selectRow,
    fn: (selectedRowsIds, id) => uniq([...selectedRowsIds, id]),
    target: selectionModelChanged
  });

  sample({
    source: $data.map(data => data.map(getRowId)),
    clock: selectAllRows,
    target: selectionModelChanged
  });

  sample({
    source: $selectedRowsIds,
    clock: unselectRow,
    fn: (selectedRowsIds, id) => selectedRowsIds.filter(i => i !== id),
    target: selectionModelChanged
  });

  sample({
    clock: unselectAllRows,
    fn: () => [],
    target: selectionModelChanged
  });

  sample({
    clock: sortModelChange,
    target: $sortModel
  });

  return {
    $data: $data,
    $unsavedChanges: $unsavedChanges,
    $updatedRows: $updatedRows,
    $rows: $rows,
    $highlightedRows: $highlightedRows.map(d => d),
    $pagination: $receivedPagination.map(d => d),
    $visibleRowsIds: $visibleRowsIds.map(d => d),
    $rowModesModel: $rowModesModel.map(d => d),
    $errors: $errors.map(d => d),
    $selectedRowsIds: $selectedRowsIds.map(d => d),
    $sortModel: $sortModel.map(d => d),

    resetCellValue,
    resetAllChanges,
    resetRowChanges,
    cellValueChanged,
    highlightRows,
    tableMounted: apiRefChanged.map(Boolean),
    ...(type === 'client' ? { addRows, removeRowsByIds } : {}),
    setRowMode,
    setAllRowsMode,

    setErrors,
    resetCellError,
    addCellError,
    resetRowsErrors,

    selectRow,
    unselectRow,
    selectAllRows,
    unselectAllRows,
    selectionModelChanged,
    sortModelChange,

    __: {
      reinitAll,
      getFreshRowsFx
    },

    useDataGrid: useDataGridApiRef
  };
}
