import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from '@ghs/components';

/**
 * Generate an alert for a timeout error.
 *
 * @param {string} email the user's email
 * @param {string} url the user's current url
 * @returns {object} {{severity: string, title: string, message: React.JSX.Element}}
 */
export const generateTimeoutErrorAlert = (email, url) => {
  const timeoutMessages = [
    <>It&apos;s taking our carrier pigeon a bit longer than we had hoped to return your results. Please try again.</>,
    <>While we typically move faster than a mail carrier in a snowstorm, it seems your results have hit a snowbank. Please try again.</>,
    <>Looks like our hamster fell off his wheel trying to return your results. Please try again.</>
  ];

  return generateErrorAlert(email, url, timeoutMessages[Date.now() % timeoutMessages.length]);
};

/**
 * Generate an alert for a timeout error.
 *
 * @param {string} email the user's email
 * @param {string} url the user's current url
 * @param {React.JSX.Element} errorMessage the error message
 * @returns {object} {{severity: string, title: string, message: React.JSX.Element}}
 */
export const generateErrorAlert = (email, url, errorMessage) => {
  return {
    severity: 'error',
    title: 'Oops!',
    message: (
      <>
        {errorMessage}
        <br />
        <br />
        Email: {email}
        <br />
        URL: {url}
      </>
    )
  };
};

/**
 * Generate an alert for a timeout error.
 *
 * @param {object} props props for this component
 * @param {ContactInfo} props.contactInfo Contact information
 * @param {string} props.userEmail the user's email
 * @param {string} props.requestId the request id
 * @param {string} props.errorMessage the error message
 * @returns {object} {{severity: string, title: string, message: React.JSX.Element}}
 */
export const generateErrorAlertWithContact = ({ contactInfo, userEmail, requestId, errorMessage }) => {
  return {
    severity: 'error',
    title: 'Oops!',
    message: (
      <>
        {errorMessage ? errorMessage : 'There was an error processing your request'}. If this error persists, please contact{' '}
        {contactInfo?.email ? <Link href={`mailto:` + contactInfo.email}>{contactInfo.email}</Link> : 'your tracking supplier'} and include the following information: <br />
        <br />
        Email: {userEmail}
        <br />
        <Typography sx={{ overflowWrap: 'anywhere' }}>URL: {window.location.href}</Typography>
        <br />
        {requestId && <span>Request ID: {requestId}</span>}
      </>
    )
  };
};

/**
 * Determine if an error is due to a timeout.
 *
 * @param {Error} err the error to check
 * @returns {boolean} true if error is due to timeout
 */
export const isTimeoutError = err => {
  return err?.response?.status === 504 || (err?.code === 'ECONNABORTED' && ['timeout', 'exceeded'].every(s => err?.message?.includes(s)));
};
