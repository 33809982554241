import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { omit, propOr } from 'ramda';

import { useTheme, Button as MuiButton, Typography } from '@mui/material';

import { colors as themeColors } from '~/theme';

export const buttonVariants = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  TEXT: 'text'
};

/** @type {const} */
export const buttonColors = {
  ...omit(['TEXT'], themeColors),
  INHERIT: 'inherit'
};

export const buttonSizes = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small'
};

const typeSizeMap = {
  large: 'buttonLarge',
  medium: 'button',
  small: 'buttonSmall'
};

const Button = forwardRef(
  (
    {
      children,
      variant,
      color,
      size,
      startIcon,
      endIcon,
      disabled,
      onClick,
      ...overrides
    },
    ref
  ) => {
    const theme = useTheme();
    const colorTextPrimary = theme.palette.text.primary;

    const needsContrast =
      variant !== buttonVariants.CONTAINED && color === buttonColors.GREY;

    return (
      <MuiButton
        ref={ref}
        variant={variant}
        color={color}
        size={size}
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={disabled}
        onClick={onClick}
        sx={
          needsContrast
            ? {
                borderColor: colorTextPrimary
              }
            : null
        }
        {...overrides}
      >
        {typeof children === 'string' ? (
          <Typography
            variant={propOr('button', size, typeSizeMap)}
            color={needsContrast ? colorTextPrimary : 'inherit'}
          >
            {children}
          </Typography>
        ) : (
          children
        )}
      </MuiButton>
    );
  }
);

Button.displayName = 'Button';

Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(Object.values(buttonVariants)),
  color: PropTypes.oneOf(Object.values(buttonColors)),
  size: PropTypes.oneOf(Object.values(buttonSizes)),
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default Button;
